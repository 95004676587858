import { SET_PROJECT_SELECTED, SET_PROJECTS, SET_RECENT_PROJECTS } from '../actions/types'
import _ from 'lodash'

const INITIAL_STATE = {
  projectList: null,
  recentProjectList: null,
  projectSelected: null,
}

const projectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return { ...state, projectList: { ..._.mapKeys(action.payload, 'id') } }

    case SET_PROJECT_SELECTED:
      return { ...state, projectSelected: action.payload }

    case SET_RECENT_PROJECTS:
      return { ...state, recentProjectList: action.payload }

    default:
      return state
  }
}
export default projectReducer
