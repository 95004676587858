import { SET_DISCOUNT, SET_DISCOUNT_TITLE, SET_DISCOUNT_TEXT } from '../actions/types'

const INITIAL_STATE = {
  discount: 0,
  discountText: '',
  discountTitle: '',
}

const discountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DISCOUNT:
      return { ...state, discount: action.payload }
    case SET_DISCOUNT_TEXT:
      return { ...state, discountText: action.payload }
    case SET_DISCOUNT_TITLE:
      return { ...state, discountTitle: action.payload }
    default:
      return state
  }
}
export default discountReducer
