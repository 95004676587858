import { getAxiosInstance } from '../actions/index.js'

import axios from 'axios'

export const postNewComponent = async (projectKey, newInitiativeName) => {
  const axiosInstance = getAxiosInstance()

  return await axiosInstance.post(`/component`, {
    name: newInitiativeName,
    project: projectKey,
  })
}

export const postNewIssue = async (projectId, issueSummary, issueTypeId, epicKey, versionValue, componentValue) => {
  const axiosInstance = getAxiosInstance()

  epicKey = epicKey ? { customfield_10008: epicKey } : {}
  versionValue = versionValue ? { fixVersions: [{ name: versionValue }] } : ''
  componentValue = componentValue ? { components: [{ name: componentValue }] } : ''

  if (issueSummary) {
    issueSummary = formatIssueSummary(issueSummary)
  }

  return await axiosInstance.post(`/issue`, {
    updateHistory: true,
    fields: {
      summary: issueSummary,
      issuetype: {
        id: issueTypeId,
      },
      project: {
        id: projectId,
      },
      ...epicKey,
      ...componentValue,
      ...versionValue,
    },
  })
}

export const postNewEpic = async (projectId, issueSummary, issueTypeId) => {
  const axiosInstance = getAxiosInstance()

  return await axiosInstance.post(`/issue`, {
    updateHistory: true,
    fields: {
      summary: issueSummary,
      issuetype: {
        id: issueTypeId,
      },
      project: {
        id: projectId,
      },
      customfield_10009: issueSummary,
    },
  })
}

export const updateIssue = async (
  issueId,
  newIssueSummary = null,
  newEpic = null,
  newVersion = null,
  newComponent = null,
  newDescription = '',
  oldValue = null,
  caller,
) => {
  if (newIssueSummary) {
    newIssueSummary = formatIssueSummary(newIssueSummary)
  }
  if (!newDescription) {
    newDescription = ''
  }

  const axiosInstance = getAxiosInstance()
  let fields = {}

  switch (caller) {
    case 'issue':
      fields = {
        ...fields,
        ...(newIssueSummary !== '' && newIssueSummary !== null
          ? { summary: [{ set: newIssueSummary }] }
          : { summary: [{ set: '[EMPTY ISSUE TEMPLATE]' }] }),
      }
      break
    case 'epic':
      fields = {
        ...fields,
        ...(newEpic !== '' ? { customfield_10008: [{ set: newEpic }] } : { customfield_10008: [{ set: null }] }),
      }
      break
    case 'version':
      fields = {
        ...fields,
        ...(newVersion !== ''
          ? { fixVersions: [{ set: [{ name: newVersion }] }] }
          : { fixVersions: [{ remove: { name: oldValue } }] }),
      }
      break
    case 'component':
      fields = {
        ...fields,
        ...(newComponent !== ''
          ? { components: [{ set: [{ name: newComponent }] }] }
          : { components: [{ remove: { name: oldValue } }] }),
      }
      break
    default:
      break
  }

  return await axiosInstance.put(`/issue/${issueId}`, {
    fields: {
      description: {
        type: 'doc',
        version: 1,
        content: [
          {
            type: 'paragraph',
            content: [
              {
                text: newDescription,
                type: 'text',
              },
            ],
          },
        ],
      },
    },
    update: {
      ...fields,
    },
  })
}

export const updateIssueEstimation = async (issueId, estimationLow, estimationHigh) => {
  const axiosInstance = getAxiosInstance()

  let lowStr = estimationLow ? estimationLow.toString().replace(',', '.') : 0
  let highStr = estimationHigh ? estimationHigh.toString().replace(',', '.') : 0

  lowStr = lowStr ? parseFloat(lowStr) : 0
  highStr = highStr ? parseFloat(highStr) : 0

  return await axiosInstance.put(`/issue/${issueId}`, {
    fields: {
      customfield_11850: highStr,
      customfield_11851: lowStr,
    },
  })
}

export const postNewVersion = async (projectId, versionName) => {
  const axiosInstance = getAxiosInstance()

  return await axiosInstance.post(`/version`, {
    projectId: projectId,
    name: versionName,
  })
}

export const deleteIssue = issueId => async () => {
  const axiosDelIssues = getAxiosInstance()

  await axiosDelIssues.delete(`/issue/${issueId}`)
}

export const deleteField = async (id, caller) => {
  const axiosDelIssues = getAxiosInstance()

  if (caller === 'epic') {
    return await axiosDelIssues.delete(`/issue/${id}`)
  } else {
    return await axiosDelIssues.delete(`/${caller}/${id}`)
  }
}

export const moveRank = async issueId => {
  // const axiosMoveRank = getAxiosInstanceGreenhopper();
  // let respo = await axiosMoveRank.put(`/rest/agile/latest/issue/rank`,{
  //   "issues": ["MT-1144"],
  //   "rankBeforeIssue": "MT-1046",
  //   "rankCustomFieldId" : "10600"
  // })
}

export const bulkCreateIssue = async (data, projectId) => {
  const axiosBulkCreateIssue = getAxiosInstance()

  let formattedData = {
    issueUpdates: data.map(issue => {
      return {
        update: {},
        fields: {
          summary: formatIssueSummary(issue[3]),
          project: { id: projectId },
          issuetype: { id: 7 },
        },
      }
    }),
  }

  return await axiosBulkCreateIssue.post('/issue/bulk', {
    ...formattedData,
  })
}

export const bulkCreateEpic = async (data, projectId) => {
  const axiosBulkCreateIssue = getAxiosInstance()

  let formattedData = {
    issueUpdates: data.map(issue => {
      let summary = formatIssueSummary(issue)
      return {
        update: {},
        fields: {
          customfield_10009: summary,
          summary: summary,
          project: { id: projectId },
          issuetype: { id: 6 },
        },
      }
    }),
  }

  return await axiosBulkCreateIssue.post('/issue/bulk', {
    ...formattedData,
  })
}

export const addEpicToIssueJira = async (issueKey, epicKey) => {
  const axiosAddEpicToIssueJira = getAxiosInstance()

  return await axiosAddEpicToIssueJira.put(`/issue/${issueKey}`, {
    fields: {
      customfield_10008: epicKey,
    },
  })
}

export const getAxiosInstanceGreenhopper = () => {
  let auth = btoa('billy@reachgdigital.nl:54kQoW8NC5PzDBKjg8dB719C')

  const axiosInstance = axios.create({
    baseURL: `https://hoproj.atlassian.net`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${auth}`,
      'Access-Control-Allow-Origin': '*',
    },
  })
  return axiosInstance
}

export const formatIssueSummary = issueSummary => {
  issueSummary = issueSummary.replace(/(\r\n|\n|\r)/gm, ' ')

  return (issueSummary = issueSummary.length > 254 ? issueSummary.substring(0, 250) + '...' : issueSummary)
}
