import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { setIssues, setVersions, setComponents } from '../actions'
import ContentWrapper from '../components/ContentWrapper'
import IssueHotTable from '../components/IssueHotTable'
import 'handsontable/dist/handsontable.full.css'
import '../css/handsontable.css'
import history from '../history.js'
import { BallBeat } from 'react-pure-loaders'

class IssuePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      appIsGettingData: 0,
      initialLoader: 0,
    }
  }
  componentDidMount() {
    if (!this.props.auth.isSignedIn) {
      history.push('/')
    }
    if (this.props.project.projectSelected) {
      this.retrieveAllDAta(this.props.project.projectSelected.projectId)
    }

    let localEmptyRows = JSON.parse(localStorage.getItem('empty_rows'))

    if (localEmptyRows) {
      localEmptyRows.map(row => {
        try {
          this.hotTableComponent.current.hotInstance.alter('insert_row', row, 1)
        } catch (error) {}
        return null
      })
    }
  }

  retrieveAllDAta = async projectId => {
    this.setState(prevState => {
      return {
        appIsGettingData: prevState.appIsGettingData + 3,
        initialLoader: 1,
      }
    })
    await Promise.all([
      this.props.setIssues(projectId),
      this.props.setVersions(projectId),
      this.props.setComponents(projectId),
    ])
    this.setState(prevState => {
      return {
        appIsGettingData: prevState.appIsGettingData - 3,
        initialLoader: 0,
      }
    })
  }

  render() {
    if (this.state.initialLoader || !this.props.issue.issueList || !this.props.issue.issueList.issues) {
      return (
        <ContentWrapper>
          <BallBeat color={'#123abc'} loading />
        </ContentWrapper>
      )
    } else if (this.props.issue.issueList.issues.length !== 0) {
      if (this.props.issue.issueList.issues[0].fields.project.id !== this.props.project.projectSelected.projectId) {
        return (
          <ContentWrapper>
            <BallBeat color={'#123abc'} loading />
          </ContentWrapper>
        )
      } else {
        return <IssueHotTable appIsGettingData={this.state.appIsGettingData} />
      }
    } else {
      return <IssueHotTable appIsGettingData={this.state.appIsGettingData} />
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    components: state.component,
    project: state.projects,
    issue: state.issue,
    versions: state.versions,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setIssues,
    setVersions,
    setComponents,
  })(IssuePage),
)
