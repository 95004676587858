import React from "react";
import "../css/projectPage.css"


class SearchBar extends React.Component {

    change = (e) => {
      this.props.onChange(e);
    }

    render() {
      return (
        <div className="ui icon input">
          <input type="text" onChange={(e) => {this.change(e.target.value)}} placeholder="Search projects..." />
          <i aria-hidden="true" className="search icon"></i>
        </div>
      )
    }
}
export default SearchBar