import React from 'react'
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs'
// import Button, { ButtonGroup } from '@atlaskit/button';
import TextField from '@atlaskit/textfield'
import Select from '@atlaskit/select'
import '../css/handsontable.css'
import PageHeader from '@atlaskit/page-header'
import history from '../history'
// import { Link } from 'react-router-dom';
import logo from '../images/logo.svg'
import '../css/pageHeader.css'

export default class PageHeaderClass extends React.Component {
  breadcrumbsHomePage = (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        text='Projecten'
        key='Projecten'
        onClick={() => {
          history.push(`/`)
        }}
      ></BreadcrumbsItem>
    </BreadcrumbsStateless>
  )
  breadcrumbsStoryPage = (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        text='Projecten'
        key='Projecten'
        onClick={() => {
          history.push(`/`)
        }}
      ></BreadcrumbsItem>
      <BreadcrumbsItem text={this.props.title} key='story-page' />
    </BreadcrumbsStateless>
  )
  breadcrumbsDraaiTabelPage = (
    <BreadcrumbsStateless onExpand={() => {}}>
      <BreadcrumbsItem
        text='Projecten'
        key='Projecten'
        onClick={() => {
          history.push(`/`)
        }}
      ></BreadcrumbsItem>
      <BreadcrumbsItem
        text={this.props.title}
        key='story-page'
        onClick={() => {
          history.push(`/projects/${this.props.projectId}`)
        }}
      />
      <BreadcrumbsItem text='Draaitabel' key='draaiTabel' />
    </BreadcrumbsStateless>
  )

  breadcrumbsSimulatiePage = (
    <div className='breadcrumbs-wrapper'>
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          text='Projecten'
          key='Projecten'
          onClick={() => {
            history.push(`/`)
          }}
        ></BreadcrumbsItem>
        <BreadcrumbsItem
          text={this.props.title}
          key='story-page'
          onClick={() => {
            history.push(`/projects/${this.props.projectId}`)
          }}
        />
        <BreadcrumbsItem text='Simulatie' key='Simulatie' />
      </BreadcrumbsStateless>
    </div>
  )

  breadcrumbsSimulatieResultPage = (
    <div className='breadcrumbs-wrapper'>
      <BreadcrumbsStateless onExpand={() => {}}>
        <BreadcrumbsItem
          text='Projecten'
          key='Projecten'
          onClick={() => {
            history.push(`/`)
          }}
        ></BreadcrumbsItem>
        <BreadcrumbsItem
          text={this.props.title}
          key='story-page'
          onClick={() => {
            history.push(`/projects/${this.props.projectId}`)
          }}
        />
        <BreadcrumbsItem
          text='Simulatie'
          key='Simulatie'
          onClick={() => {
            history.push(`/projects/${this.props.projectId}/simulatie`)
          }}
        />
        <BreadcrumbsItem text='Resultaat' key='Resultaat' />
      </BreadcrumbsStateless>
    </div>
  )

  logoContent = (<img className='logo' src={logo} alt='Reach logo'></img>)

  barContent = (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '0 0 200px' }}>
        <TextField isCompact placeholder='Filter' aria-label='Filter' />
      </div>
      <div style={{ flex: '0 0 200px', marginLeft: 8 }}>
        <Select spacing='compact' placeholder='Choose an option' aria-label='Choose an option' />
      </div>
    </div>
  )

  render() {
    if (this.props.page === 'storypage') {
      //storypage
      return (
        <PageHeader
          breadcrumbs={this.breadcrumbsStoryPage}
          actions={this.logoContent}
          //bottomBar={this.barContent}
          className='breadcrumbs-container'
        >
          <div className='page-header-title'>
            Project Overzicht {this.props.title}
            <a target='__blank' href={`https://hoproj.atlassian.net/browse/${this.props.projectKey}`}>
              <i className='icon external icon-external-link-title' />
            </a>
          </div>
        </PageHeader>
      )
    } else if (this.props.page === 'homepage') {
      return (
        //homepage
        <PageHeader breadcrumbs={this.breadcrumbsHomePage} actions={this.logoContent} className='breadcrumbs-container'>
          Project Overzicht {this.props.title}
        </PageHeader>
      )
    } else if (this.props.page === 'draaitabel') {
      return (
        //simulatie pagina
        <PageHeader
          breadcrumbs={this.breadcrumbsDraaiTabelPage}
          actions={this.logoContent}
          className='breadcrumbs-container'
        >
          Draaitabel
        </PageHeader>
      )
    } else if (this.props.page === 'simulatie') {
      return (
        //simulatie pagina
        <PageHeader
          breadcrumbs={this.breadcrumbsSimulatiePage}
          actions={this.logoContent}
          className='breadcrumbs-container'
        >
          Simulatie - {this.props.title}
        </PageHeader>
      )
    } else if (this.props.page === 'simulatieResult') {
      return (
        <div className='pageheader-wrapper'>
          <PageHeader
            breadcrumbs={this.breadcrumbsSimulatieResultPage}
            actions={this.logoContent}
            className='breadcrumbs-container'
          >
            <p className='title-simulation-result-page'>Simulatie - {this.props.title}</p>
          </PageHeader>
        </div>
      )
    }
  }
}
