import { SET_ASSIGNEES } from '../actions/types'

const INITIAL_STATE = {
  assigneeList: null,
}

const assigneeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ASSIGNEES:
      return { ...state, assigneeList: action.payload }
    default:
      return state
  }
}
export default assigneeReducer
