import React from 'react'
import { connect } from 'react-redux'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { Table } from 'semantic-ui-react'
import '../css/SimulatieResultPage.css'
import { ReactRenderer } from '@atlaskit/renderer'
import { Provider } from '@atlaskit/smart-card'
import { IntlProvider } from 'react-intl'

class GetSimulatedResult extends React.Component {
  constructor(props) {
    super(props)

    //state for component
    this.state = {
      data: [],
      countResults: {},
      amountOfSimulations: 1000000,
      graphState: 'percentage',
      threshHold5: '',
      threshHold20: '',
      threshHold50: '',
      threshHold80: '',
      hoursOf5: '',
      hoursOf20: '',
      hoursOf50: '',
      hoursOf80: '',
      budget: '',
      percentielBudget: '',
      fullEpicEstimations: [],
      fullCountedEpicEstimations: [],
      epicHoursOf5Value: [],
      epicHoursOf20Value: [],
      epicHoursOf50Value: [],
      epicHoursOf45Value: [],
      epicHoursOf80Value: [],
      epicHoursOf60Value: [],
    }
  }

  componentDidMount() {
    if (
      this.props.riskRange.riskRangeHigh.length &&
      this.props.riskRange.riskRangeLow.length &&
      this.props.language.languageSelected
    ) {
      this.startSimulation()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      this.props.riskRange.riskRangeHigh.length &&
      this.props.riskRange.riskRangeLow.length &&
      this.props.language.languageSelected
    ) {
      this.startSimulation()
    }
  }

  getEpics = (selectedComponent, selectedVersion) => {
    const issues = this.props.issue.issueList.issues.filter(issue => {
      // get epics

      const isEpic = issue.fields.issuetype.name === 'Epic'
      if (isEpic) {
        //when the issue is not an epic
        return false
      }

      if (!selectedComponent && !selectedVersion) {
        //when no component or version is selected
        return issue
      }

      const issueHasComponent = !!issue.fields.components.length //does the epic have a component?
      const issueHasVersion = !!issue.fields.fixVersions.length //does the epic have a version?

      //if something is selected and the issue does not have that attribute
      if ((selectedComponent && !issueHasComponent) || (selectedVersion && !issueHasVersion)) {
        return false
      }

      if (selectedComponent && selectedVersion) {
        return (
          selectedComponent &&
          issue.fields.components[0].id === selectedComponent &&
          selectedVersion &&
          issue.fields.fixVersions[0].id === selectedVersion
        )
      } else {
        return (
          (selectedComponent && issue.fields.components[0].id === selectedComponent) ||
          (selectedVersion && issue.fields.fixVersions[0].id === selectedVersion)
        )
      }
    })

    const epicKeys = issues.map(issue => {
      return issue.fields.customfield_10008
    })

    return this.props.issue.issueList.issues.filter(issue => {
      const isEpic = issue.fields.issuetype.name === 'Epic'
      if (!isEpic) {
        //when the issue is not an epic
        return false
      }
      return epicKeys.includes(issue.key)
    })
  }

  startSimulation = () => {
    const selectedComponent = this.props.components.componentSelected
      ? this.props.components.componentSelected.componentId
      : false
    const selectedVersion = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : false

    const epics = this.getEpics(selectedComponent, selectedVersion)

    const fullEpicObject = epics.map(epic => {
      //get random inschattingen voor epics
      return this.getRandomEstimationNumbs(epic, this.state.amountOfSimulations)
    })

    const fullProjectArray = []

    for (let y = 0; y < this.state.amountOfSimulations; y++) {
      //optellen epics inschattingen
      for (let x = 0; x < fullEpicObject.length; x++) {
        fullProjectArray[y] = !fullProjectArray[y] ? fullEpicObject[x][y] : fullProjectArray[y] + fullEpicObject[x][y]
      }
    }

    const fullProjectArrayRounded = fullProjectArray.map(number => {
      //afronden getallen
      return Math.ceil(number)
    })

    const fullEpicObjectRoundedCountedResults = fullEpicObject.map(epicObject => {
      //voor tabel bovenaan de pagina het rounden van epics en het optellen
      const epicRounded = epicObject.map((epic, i) => {
        return Math.ceil(epic)
      })

      let countResultsPerEpic = {}

      epicRounded.forEach(x => {
        //optellen per epics
        countResultsPerEpic[x] = (countResultsPerEpic[x] || 0) + 1
      })

      return countResultsPerEpic
    })

    const countResults = {} //hoevaak komt een inschatting voor?
    let matchedBudget = []

    fullProjectArrayRounded.forEach(function(x) {
      countResults[x] = (countResults[x] || 0) + 1
    })

    let stepValue = Math.round(
      (Object.keys(countResults)[Object.keys(countResults).length - 1] - Object.keys(countResults)[0]) / 10,
    )

    const firstKeyInCountResults = Number(Object.keys(countResults)[0]) //Object.keys(countResults)[Object.keys(countResults).length-1]

    const columns = []
    for (let x = 0; x < 10; x++) {
      const column = Object.keys(countResults).filter(hours => {
        const matchesFirst = hours >= firstKeyInCountResults + x * stepValue
        const matchesLast = hours <= firstKeyInCountResults + ((x + 1) * stepValue - 1)
        return matchesFirst && matchesLast
      })
      columns.push(column)
    }

    const accumaltedSimulationsPerStepValue = columns.map(column => {
      if (column.length) {
        return column.reduce((accumulator, currentValue, index) => {
          if (index === 1) {
            accumulator = countResults[accumulator]
          }
          return Number(accumulator) + Number(countResults[currentValue])
        })
      } else {
        return false
      }
    })

    const data = columns.map((column, i) => {
      return {
        days: Math.round(column[0] / 8) + '-' + Math.round(column[column.length - 1] / 8),
        simulaties: accumaltedSimulationsPerStepValue[i],
      }
    })

    let threshHold = 0
    let threshHold5 = []
    let threshHold20 = []
    let threshHold50 = []
    let threshHold80 = []
    let xAsBudgetLine = ''
    let percentielBudget = ''

    data.forEach((value, i) => {
      if (threshHold > this.state.amountOfSimulations * 0.05) {
        threshHold5.push(data[i - 1].days)
      }
      if (threshHold > this.state.amountOfSimulations * 0.2) {
        threshHold20.push(data[i - 1].days)
      }
      if (threshHold > this.state.amountOfSimulations * 0.5) {
        threshHold50.push(data[i - 1].days)
      }
      if (threshHold > this.state.amountOfSimulations * 0.8) {
        threshHold80.push(data[i - 1].days)
      }
      threshHold += value.simulaties

      let splitteddays = value.days.split('-')

      if (splitteddays[0] <= matchedBudget[0] && splitteddays[1] >= matchedBudget[0]) {
        xAsBudgetLine = value.days
        percentielBudget = Math.round((threshHold / this.state.amountOfSimulations) * 100)
      }
    })

    let sumOfCount = 0
    let hoursOf5Key = []
    let hoursOf20Key = []
    let hoursOf50Key = []
    let hoursOf80Key = []

    let hoursOf5
    let hoursOf20
    let hoursOf50
    let hoursOf80

    Object.values(countResults).forEach((value, i) => {
      sumOfCount += value
      if (sumOfCount >= this.state.amountOfSimulations * 0.05) {
        hoursOf5Key.push(i)
      }
      if (sumOfCount >= this.state.amountOfSimulations * 0.2) {
        hoursOf20Key.push(i)
      }
      if (sumOfCount >= this.state.amountOfSimulations * 0.5) {
        hoursOf50Key.push(i)
      }
      if (sumOfCount >= this.state.amountOfSimulations * 0.8) {
        hoursOf80Key.push(i)
      }
    })

    Object.keys(countResults).forEach((key, i) => {
      if (i === hoursOf5Key[0]) {
        hoursOf5 = parseInt(key)
      }
      if (i === hoursOf20Key[0]) {
        hoursOf20 = parseInt(key)
      }
      if (i === hoursOf50Key[0]) {
        hoursOf50 = parseInt(key)
      }
      if (i === hoursOf80Key[0]) {
        hoursOf80 = parseInt(key)
      }
    })

    let epicHoursOf5Value = []
    let epicHoursOf20Value = []
    let epicHoursOf50Value = []
    let epicHoursOf45Value = []
    let epicHoursOf80Value = []
    let epicHoursOf60Value = []

    fullEpicObjectRoundedCountedResults.map(epic => {
      let totalSumsOfEstimations = 0
      let epicHoursOf5Key = []
      let epicHoursOf20Key = []
      let epicHoursOf50Key = []
      let epicHoursOf45Key = []
      let epicHoursOf80Key = []
      let epicHoursOf60Key = []

      Object.values(epic).forEach((value, i) => {
        totalSumsOfEstimations += value
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.05) {
          epicHoursOf5Key.push(i)
        }
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.2) {
          epicHoursOf20Key.push(i)
        }
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.5) {
          epicHoursOf50Key.push(i)
        }
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.45) {
          epicHoursOf45Key.push(i)
        }
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.8) {
          epicHoursOf80Key.push(i)
        }
        if (totalSumsOfEstimations >= this.state.amountOfSimulations * 0.6) {
          epicHoursOf60Key.push(i)
        }
      })

      Object.keys(epic).forEach((key, i) => {
        if (i === epicHoursOf5Key[0]) {
          epicHoursOf5Value.push(parseInt(key))
        }
        if (i === epicHoursOf20Key[0]) {
          epicHoursOf20Value.push(parseInt(key))
        }
        if (i === epicHoursOf50Key[0]) {
          epicHoursOf50Value.push(parseInt(key))
        }
        if (i === epicHoursOf45Key[0]) {
          epicHoursOf45Value.push(parseInt(key))
        }
        if (i === epicHoursOf80Key[0]) {
          epicHoursOf80Value.push(parseInt(key))
        }
        if (i === epicHoursOf60Key[0]) {
          epicHoursOf60Value.push(parseInt(key))
        }
      })
      return true
    })

    this.setState(
      {
        fullCountedEpicEstimations: fullProjectArray,
        fullEpicEstimations: fullEpicObject,
        budget: xAsBudgetLine,
        percentielBudget,
        threshHold5: threshHold5[0],
        threshHold20: threshHold20[0],
        threshHold50: threshHold50[0],
        threshHold80: threshHold80[0],
        epicHoursOf5Value,
        epicHoursOf20Value,
        epicHoursOf50Value,
        epicHoursOf45Value,
        epicHoursOf80Value,
        epicHoursOf60Value,
        hoursOf5,
        hoursOf20,
        hoursOf50,
        hoursOf80,
        countResults: data,
      },
      () => {
        this.formatFinalArray()
      },
    )
  }

  formatFinalArray = () => {
    let simulaties
    let data = this.state.countResults

    if (this.state.graphState === 'percentage') {
      data = data.map((value, i) => {
        //formatten data array en percentage totaal
        simulaties = Number(parseFloat((value.simulaties / this.state.amountOfSimulations) * 100).toFixed(1))
        return { ...data[i], simulaties }
      })
    } else {
      data = data.map((value, i) => {
        //formatten data array zonder percentage
        simulaties = value.simulaties
        return { ...data[i], simulaties }
      })
    }
    this.setState({ data })
  }

  getRandomEstimationNumbs = epic => {
    let riskRangeLow = parseFloat(this.props.riskRange.riskRangeLow)
    let riskRangeHigh = parseFloat(this.props.riskRange.riskRangeHigh)

    const randomEst = []
    for (let y = 0; y < this.state.amountOfSimulations; y++) {
      const randomNr =
        Math.random() * (epic.fields.customfield_11850 - epic.fields.customfield_11851) + epic.fields.customfield_11851
      const randomRisk = Math.random() * (riskRangeHigh - riskRangeLow) + riskRangeLow
      randomEst.push(randomNr * randomRisk)
    }
    return randomEst
  }

  getAllEpicsAndEstimations = () => {
    const epicHoursOf20Value = this.state.epicHoursOf20Value
    const epicHoursOf45Value = this.state.epicHoursOf45Value
    const epicHoursOf80Value = this.state.epicHoursOf80Value
    const epicHoursOf60Value = this.state.epicHoursOf60Value

    const languageSelected = this.props.language.languageSelected ? this.props.language.languageSelected.language : 'NL'

    const selectedComponent = this.props.components.componentSelected
      ? this.props.components.componentSelected.componentId
      : false
    const selectedVersion = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : false

    const epics = this.getEpics(selectedComponent, selectedVersion)

    let sumOfHours45 = 0
    let sumOfHours60 = 0

    return epics.map((epic, i) => {
      let estRounded20 = epicHoursOf20Value.length ? Math.ceil(epicHoursOf20Value[i] / 8) : 0
      let estRounded80 = epicHoursOf80Value.length ? Math.ceil(epicHoursOf80Value[i] / 8) : 0

      const stringForCell20 = estRounded20
      let stringForCell80
      if (languageSelected === 'NL') {
        stringForCell80 = estRounded80 === 1 ? estRounded80 + ' dag' : estRounded80 + ' dagen'
      } else {
        stringForCell80 =
          estRounded80 === 1 && languageSelected === 'EN' ? estRounded80 + ' day' : estRounded80 + ' days'
      }

      sumOfHours45 += epicHoursOf45Value[i]
      sumOfHours60 += epicHoursOf60Value[i]

      const colorOf45 =
        sumOfHours45 * this.props.hourlyRate < this.state.hoursOf20 * this.props.hourlyRate ? 'positive' : 'warning'
      const colorOf60 =
        sumOfHours60 * this.props.hourlyRate < this.state.hoursOf20 * this.props.hourlyRate ? 'positive' : 'warning'

      let colorOfEffort

      if (colorOf45 === 'positive' && colorOf60 === 'positive') {
        colorOfEffort = 'positive'
      } else if (colorOf45 === 'warning' && colorOf60 === 'warning') {
        colorOfEffort = 'negative'
      } else {
        colorOfEffort = 'warning'
      }

      const issues = this.getChildrenOfEpic(epic)
      let epicDescription
      if (epic.fields.description !== null) {
        if (epic.fields.description.content.length > 0) {
          epicDescription = (
            <div className='description-epic-table'>
              <Provider>
                <IntlProvider locale='en'>
                  <ReactRenderer document={epic.fields.description} />
                </IntlProvider>
              </Provider>
            </div>
          )
        }
      }

      return (
        <Table.Row key={i}>
          <Table.Cell>
            {epic.fields.customfield_10009}
            {epicDescription}

            {issues.map((issue, i) => {
              return (
                <p className='user-story-epic-table' key={i}>
                  &middot; {issue.fields.summary}
                </p>
              )
            })}
          </Table.Cell>
          <Table.Cell className={colorOfEffort}>
            {stringForCell20} - {stringForCell80}
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  getChildrenOfEpic = epic => {
    return this.props.issue.issueList.issues.filter(issue => {
      return issue.fields.customfield_10008 === epic.key
    })
  }

  getSelectedComponentName = componentId => {
    const selectedComponent = this.props.components.componentList.filter(component => {
      return component.id === componentId
    })

    return selectedComponent.length ? selectedComponent[0].name : ''
  }

  getSelectedVersionName = versionId => {
    const selectedVersion = this.props.versions.versionList.filter(version => {
      return version.id === versionId
    })

    return selectedVersion.length ? selectedVersion[0].name : ''
  }

  render() {
    let selectedComponentTitle = ''
    let selectedVersionTitle = ''
    if (this.props.components.componentSelected) {
      selectedComponentTitle =
        this.props.components.componentSelected.componentId !== ''
          ? '- ' + this.getSelectedComponentName(this.props.components.componentSelected.componentId)
          : ''
    }
    if (this.props.versions.versionSelected) {
      selectedVersionTitle =
        this.props.versions.versionSelected.versionId !== ''
          ? '- ' + this.getSelectedVersionName(this.props.versions.versionSelected.versionId)
          : ''
    }
    const languageSelected = this.props.language.languageSelected ? this.props.language.languageSelected.language : 'NL'
    const discount = this.props.discount ? this.props.discount.discount : 0

    const hoursOf20Price = this.state.hoursOf20 * this.props.hourlyRate
    const hoursOf80Price = this.state.hoursOf80 * this.props.hourlyRate

    const discountOfhours20 = (hoursOf20Price * discount) / 100
    const discountOfHours80 = (hoursOf80Price * discount) / 100

    const discountText = this.props.discount ? this.props.discount.discountText : false
    const discountTitle = this.props.discount ? this.props.discount.discountTitle : false

    return (
      <div>
        <h1 className='text-titles'>
          {languageSelected === 'NL'
            ? `Hoofdonderwerpen ${selectedComponentTitle} ${selectedVersionTitle}`
            : `Epics ${selectedComponentTitle} ${selectedVersionTitle}`}
        </h1>
        <br />
        <div className='wrapper-text-default'>
          <p className='p-default first'>
            {languageSelected === 'NL'
              ? ` De basis voor deze begroting zijn de epics (hoofdonderwerpen) die we herkennen binnen het project. Per epic
            schatten we de minimaal benodigde en maximaal benodigde hoeveelheid tijd. Onderstaande tabel toont een
            overzicht van de epics in dagen.`
              : `The basis for this budget are the epics (main subjects) that we recognize within the project. We estimate the minimum and
              maximum amount of time required per epic. The table below shows an overview of the epics in days.`}
          </p>
        </div>
        <Table celled className='epic-table-simulation'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Epic</Table.HeaderCell>
              <Table.HeaderCell>
                {languageSelected === 'NL' ? `Inspanning in dagen` : `Effort in days`}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.getAllEpicsAndEstimations()}</Table.Body>
        </Table>
        <hr className='horizontal second'></hr>
        <h1 className='text-titles'>
          {languageSelected === 'NL'
            ? `Begroting ${selectedComponentTitle} ${selectedVersionTitle}`
            : `Budget ${selectedComponentTitle} ${selectedVersionTitle}`}
        </h1>
        <br />
        <div className='wrapper-text-default'>
          <p className='p-default second'>
            {languageSelected === 'NL'
              ? `        Op basis van de schattingen per onderwerp, gebruiken we een systeem dat simulaties uitvoert om te bepalen
            wat een realistische begroting is van het budget.`
              : `Based on the estimates per subject, we use a system that performs simulations to determine what a realistic budget is for
the project`}
            <br />
            <br />
            {languageSelected === 'NL'
              ? `
                Er is 5% kans dat het benodigde budget minder dan €
               `
              : `There is a 5% chance that the required budget will be less than € `}
            {formatMoney(this.state.hoursOf5 * this.props.hourlyRate, 0)},-{' '}
            {languageSelected === 'NL' ? `bedraagt` : ``} <br />
            {languageSelected === 'NL'
              ? `    Er is 20% kans dat het benodigde budget minder dan € `
              : `There is a 20% chance that the required budget will be less than € `}
            {formatMoney(this.state.hoursOf20 * this.props.hourlyRate, 0)},-{' '}
            {languageSelected === 'NL' ? `bedraagt` : ``} <br />
            {languageSelected === 'NL'
              ? `Er is 50% kans dat het benodigde budget minder dan € `
              : `There is a 50% chance that the required budget will be less than € `}
            {formatMoney(this.state.hoursOf50 * this.props.hourlyRate, 0)},-{' '}
            {languageSelected === 'NL' ? `bedraagt` : ``}
            <br />
            {languageSelected === 'NL'
              ? `Er is 80% kans dat het benodigde budget minder dan € `
              : `There is an 80% chance that the required budget will be less than € `}
            {formatMoney(this.state.hoursOf80 * this.props.hourlyRate, 0)},-{' '}
            {languageSelected === 'NL' ? `bedraagt` : ``}
          </p>{' '}
        </div>
        <Table className='effort-table' celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{languageSelected === 'NL' ? `Percentiel` : `Percentile`} </Table.HeaderCell>
              <Table.HeaderCell>
                {languageSelected === 'NL' ? `Inspanning in dagen` : `Effort in days`}
              </Table.HeaderCell>
              <Table.HeaderCell>Budget</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>5%</Table.Cell>
              <Table.Cell>{this.state.hoursOf5 ? Math.round(this.state.hoursOf5 / 8) : 0}</Table.Cell>
              <Table.Cell> € {formatMoney(this.state.hoursOf5 * this.props.hourlyRate, 0)},-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>20%</Table.Cell>
              <Table.Cell>{this.state.hoursOf20 ? Math.round(this.state.hoursOf20 / 8) : 0}</Table.Cell>
              <Table.Cell> € {formatMoney(this.state.hoursOf20 * this.props.hourlyRate, 0)},-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>50%</Table.Cell>
              <Table.Cell>{this.state.hoursOf50 ? Math.round(this.state.hoursOf50 / 8) : 0}</Table.Cell>
              <Table.Cell>€ {formatMoney(this.state.hoursOf50 * this.props.hourlyRate, 0)},-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>80%</Table.Cell>
              <Table.Cell>{this.state.hoursOf80 ? Math.round(this.state.hoursOf80 / 8) : 0}</Table.Cell>
              <Table.Cell> € {formatMoney(this.state.hoursOf80 * this.props.hourlyRate, 0)},-</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <div className='wrapper-text-default'>
          <p className='p-default third'>
            <span>
              {languageSelected === 'NL'
                ? `  Als we de 20% meest optimale en 20% minst optimale schattingen niet meerekenen, dan verwachten we dat
              binnen het budgetbereik van € ${formatMoney(this.state.hoursOf20 * this.props.hourlyRate, 0)},- en € 
              ${formatMoney(this.state.hoursOf80 * this.props.hourlyRate, 0)},- het merendeel van de in de begroting
              opgenomen onderdelen voor het project te realiseren zijn.`
                : `
                 If we do not include the 20% most optimal and 20% least optimal estimates, then we expect that within the budget range
              of € ${formatMoney(this.state.hoursOf20 * this.props.hourlyRate, 0)} and € ${formatMoney(
                    this.state.hoursOf80 * this.props.hourlyRate,
                    0,
                  )}, the majority of the budgeted epics for the project will be realized.
              `}
            </span>
          </p>
        </div>

        {Number(discount) && languageSelected === 'NL' ? (
          <div className='discount-wrapper'>
            {discountTitle ? <h4>{discountTitle}</h4> : <h4>Special aanbieding</h4>}
            {discountText ? (
              <p>{discountText}</p>
            ) : (
              <p>
                Het project betreft een eerste kennismaking. We zijn erg enthousiast om deze nieuwe samenwerking te
                starten. Om die reden willen we dit eerste project aanbieden tegen een speciale eenmalige projectkorting
                van {discount}%. Daarmee willen we de volgende aanbieding doen:
              </p>
            )}

            <p>
              Als we de 20% meest optimale en 20% minst optimale schattingen niet meerekenen, dan verwachten we dat
              binnen het budgetbereik van <span className='old-price'>€ {formatMoney(hoursOf20Price, 0)},- </span>
              <span className='new-price'>€ {formatMoney(hoursOf20Price - discountOfhours20, 0)},- </span>
              en <span className='old-price'>€ {formatMoney(hoursOf80Price, 0)},- </span>
              <span className='new-price'>€ {formatMoney(hoursOf80Price - discountOfHours80, 0)},- </span>
              het merendeel van de in de begroting opgenomen onderdelen voor het project te realiseren zijn.
            </p>
          </div>
        ) : (
          ''
        )}
        {Number(discount) && languageSelected !== 'NL' ? (
          <div className='discount-wrapper'>
            {discountTitle ? <h4>{discountTitle}</h4> : <h4>Special offer</h4>}
            {discountText ? (
              <p>{discountText}</p>
            ) : (
              <p>
                The project is an initial introduction. We are very excited to start this new partnership. That is why
                we want to offer this first project at a special one-off project discount of {discount}
                %:
              </p>
            )}
            <p>
              If we do not include the 20% most optimal and 20% least optimal estimates, we expect that within the
              budget range of <span className='old-price'>€ {formatMoney(hoursOf20Price, 0)},- </span>
              <span className='new-price'>€ {formatMoney(hoursOf20Price - discountOfhours20, 0)},- </span>
              and <span className='old-price'>€ {formatMoney(hoursOf80Price, 0)},- </span>
              <span className='new-price'>€ {formatMoney(hoursOf80Price - discountOfHours80, 0)},- </span>
              the majority of the budget included parts for the project can be realized.
            </p>
          </div>
        ) : (
          ''
        )}

        <hr className='horizontal'></hr>
        <h1 className='text-titles'>
          {languageSelected === 'NL' ? `Histogram van project inspanning` : `Project Effort Histogram`}{' '}
          {selectedComponentTitle} {selectedVersionTitle}
        </h1>
        <br />
        <div className='wrapper-text-default'>
          <p className='p-default fourth'>
            {languageSelected === 'NL'
              ? ` Aantal simulaties tussen het weergegeven bereik 
            `
              : `Number of simulations between the displayed range`}

            <span style={{ fontSize: '13px' }}>
              ({this.props.riskRange.riskRangeLow} - {this.props.riskRange.riskRangeHigh})
            </span>
          </p>
        </div>
        <br />
        <div className='bar-container'>
          <BarChart
            data={this.state.data}
            width={1200}
            height={600}
            margin={{ top: 5, right: 30, left: 20, bottom: 50 }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              tick={<CustomXasixTick hourlyRate={this.props.hourlyRate} />}
              dataKey='days'
              label={{
                value: languageSelected === 'NL' ? `Inspanning in dagen` : `Effort in days`,
                position: 'insideBottomRight',
                offset: -15,
              }}
            />
            <YAxis
              label={{
                value: languageSelected === 'NL' ? `Procent` : `Percent`,
                angle: -90,
                position: 'insideLeft',
                offset: -10,
              }}
            ></YAxis>
            <Tooltip />
            <Legend layout='vertical' verticalAlign='middle' align='right' />
            <Bar
              isAnimationActive={false}
              label={<CustomizedLabel />}
              name={languageSelected === 'NL' ? `Procent van totale simulaties` : `Percent of total simulations`}
              dataKey='simulaties'
              fill='#7cb5ec'
            />
          </BarChart>
        </div>
      </div>
    )
  }
}

export const formatMoney = (amount, decimalCount = 2, decimal = ',', thousands = '.') => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.log(e)
  }
}

export const CustomXasixTick = props => {
  const { x, y } = props
  return (
    <text x={x} y={y} dy={5} fontSize='14' fontFamily='sans-serif' textAnchor='middle'>
      <tspan x={x} y={y} dy={7}>
        {props.payload.value}
      </tspan>
      <tspan x={x} y={y} dy={20} fontSize='9'>
        {getBudgetForDays(props.payload.value, props.hourlyRate)}
      </tspan>
    </text>
  )
}
export const getBudgetForDays = (days, hourlyRate) => {
  const splittedDays = days.split('-')
  const budgetLow = Number(Math.round((splittedDays[0] * hourlyRate * 8) / 1000))
  const budgetHigh = Number(Math.round((splittedDays[1] * hourlyRate * 8) / 1000))
  return '(' + budgetLow + 'k - ' + budgetHigh + 'k)'
}

export const CustomizedLabel = props => {
  const { x, y, value } = props
  return (
    <text x={x} y={y} dx={30} dy={-10} fontSize='16' fontFamily='sans-serif' textAnchor='middle'>
      {value}%
    </text>
  )
}

const mapStateToProps = state => {
  return {
    issue: state.issue,
    riskRange: state.riskRange,
    budget: state.budget,
    hourlyRate: state.hourlyRate.hourlyRate,
    project: state.projects,
    components: state.component,
    versions: state.versions,
    language: state.language,
    discount: state.discount,
  }
}

export default connect(mapStateToProps)(GetSimulatedResult)
