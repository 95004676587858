import { SET_PRIORITIES } from '../actions/types'

const INITIAL_STATE = {
  priorityList: null,
}

const priorityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRIORITIES:
      return { ...state, priorityList: action.payload }
    default:
      return state
  }
}
export default priorityReducer
