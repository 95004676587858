import PropTypes from 'prop-types'
import React from 'react'

import JiraAuth from '../components/jiraAuth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { signIn, setProjects } from '../actions'
import ProjectTable from '../components/ProjectTable'

import LoginPage from '../components/LoginPage'
import ContentWrapper from '../components/ContentWrapper'
import { BallBeat } from 'react-pure-loaders'

class HomePage extends React.Component {
  componentDidMount() {
    if (this.props.isSignedIn) {
      this.props.setProjects()
    }
  }

  static contextTypes = {
    showModal: PropTypes.func,
    addFlag: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
  }

  render() {
    if (localStorage.getItem('AuthToken')) {
      this.props.signIn(1, localStorage.getItem('AuthToken'), localStorage.getItem('RefreshToken')) //actioncreator
    }
    if (
      !this.props.location.search &&
      !this.props.projectList &&
      !this.props.isSignedIn &&
      !localStorage.getItem('AuthToken')
    ) {
      //geen code in url en geen projecten en geen localstorage == geen inlog
      return <LoginPage />
    } else if (window.location.search && !this.props.isSignedIn) {
      //code gekregen van jira api dus start Auth
      return <JiraAuth />
    }
    if (this.props.isSignedIn && !this.props.projectList) {
      this.props.setProjects()
    } else {
      return <ProjectTable />
    }
    return (
      <ContentWrapper>
        <BallBeat color={'#123abc'} loading />
      </ContentWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    projectList: state.projects.projectList,
    authToken: state.auth.userToken,
  }
}

export default withRouter(connect(mapStateToProps, { signIn, setProjects })(HomePage))
