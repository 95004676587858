import { SET_TRANSITION } from '../actions/types'

const INITIAL_STATE = {
  transitionList: null,
}

const transitionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TRANSITION:
      let payload = action.payload

      return { ...state, transitionList: action.issueId, payload }
    default:
      return state
  }
}
export default transitionReducer
