import {
  SET_COMPONENTS,
  SET_PROJECT_SELECTED,
  SET_PROJECTS,
  SIGN_IN,
  SET_COMPONENT_SELECTED,
  SET_ISSUES,
  SET_RECENT_PROJECTS,
  SET_VERSIONS,
  SET_ISSUE_TYPES,
  SET_TRANSITION,
  SET_ASSIGNEES,
  SET_PRIORITIES,
  SIGN_OUT,
  ACTIVE_FILTERS,
  SET_EMPTY_ROWS,
  SET_RISK_RANGE_LOW,
  SET_RISK_RANGE_HIGH,
  SET_BUDGET,
  SET_HOURLY_RATE,
  SET_VERSION_SELECTED,
  SET_LANGUAGE_SELECTED,
  SET_DISCOUNT,
  SET_DISCOUNT_TITLE,
} from '../actions/types'
import { cloudId, clientId, clientSecret } from '../jiraConfig'

import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { store } from '../index.js'
import { SET_DISCOUNT_TEXT } from './types'

let authToken = localStorage.getItem('AuthToken')

export const signIn = (userCode, userToken, refreshToken) => {
  localStorage.setItem('AuthToken', userToken)
  localStorage.setItem('RefreshToken', refreshToken)
  return {
    type: SIGN_IN,
    payload: { userCode, userToken, refreshToken },
  }
}

export const signOut = () => ({ type: SIGN_OUT })

export const setProjects = () => async dispatch => {
  const axiosGetProjects = getAxiosInstance()

  const firstResponse = await axiosGetProjects.get(`/project/search/?maxResults=500&startAt=0&expand=lead`)
  dispatch({ type: SET_PROJECTS, payload: firstResponse.data.values })

  let requests = []
  const pageSize = 50
  const maxResult = 50
  const totalCount = firstResponse.data.total

  const pages = Math.ceil(totalCount / pageSize)
  let results = {}

  for (let page = 0; page < pages; page++) {
    requests.push(
      axiosGetProjects.get(`/project/search/?maxResults=${maxResult}&startAt=${page * pageSize}&expand=lead`),
    )
  }
  try {
    results = await Promise.all(requests)
  } catch (error) {
    console.log(error)
  }

  let finalResult = results[0]
  results.map((result, i) => {
    if (i === 0) {
      return null
    } else {
      finalResult.data.values = [...finalResult.data.values, ...result.data.values]
    }
    return null
  })

  return finalResult ? dispatch({ type: SET_PROJECTS, payload: finalResult.data.values }) : null
}
export const setRecentProjects = () => async dispatch => {
  const axiosGetProjects = getAxiosInstance()

  const response = await axiosGetProjects.get(`/project/?expand=lead&recent=20`)
  dispatch({ type: SET_RECENT_PROJECTS, payload: response.data })
}

export const setProjectSelected = projectId => {
  return {
    type: SET_PROJECT_SELECTED,
    payload: { projectId },
  }
}

export const setComponents = projectId => async dispatch => {
  const axiosGetComponents = getAxiosInstance()

  const response = await axiosGetComponents.get(`/project/${projectId}/components`)
  dispatch({ type: SET_COMPONENTS, payload: response.data })
}

export const setComponentSelected = componentId => {
  return {
    type: SET_COMPONENT_SELECTED,
    payload: { componentId },
  }
}

export const setIssues = (projectId, maxResult = 100, startAt = 0, firstResponse = []) => async dispatch => {
  const axiosGetIssues = getAxiosInstance()

  firstResponse = await axiosGetIssues.get(
    `/search?jql=project=${projectId}%20ORDER%20BY%20RANK%20ASC&maxResults=${maxResult}&startAt=${startAt}`,
  )
  let requests = []
  const pageSize = maxResult
  const totalCount = firstResponse.data.total

  const pages = Math.ceil(totalCount / pageSize)
  let results = {}

  for (let page = 1; page < pages; page++) {
    requests.push(
      axiosGetIssues.get(
        `/search?jql=project=${projectId}%20ORDER%20BY%20RANK%20ASC&maxResults=${maxResult}&startAt=${page * pageSize}`,
      ),
    )
  }
  try {
    results = await Promise.all(requests)
  } catch (error) {
    console.log(error)
  }

  let finalResult = firstResponse
  results.map((result, i) => {
    finalResult.data.issues = [...finalResult.data.issues, ...result.data.issues]
    return null
  })

  return finalResult ? dispatch({ type: SET_ISSUES, payload: finalResult.data }) : null
}

export const setVersions = projectId => async dispatch => {
  const axiosGetVersions = getAxiosInstance()

  const response = await axiosGetVersions.get(`/project/${projectId}/versions`)
  dispatch({ type: SET_VERSIONS, payload: response.data })
}

export const setVersionSelected = versionId => {
  return {
    type: SET_VERSION_SELECTED,
    payload: { versionId },
  }
}

export const setLanguageSelected = language => {
  return {
    type: SET_LANGUAGE_SELECTED,
    payload: { language },
  }
}

export const setIssueTypes = () => async dispatch => {
  const axiosGetIssueTypes = getAxiosInstance()

  const response = await axiosGetIssueTypes.get(`/issuetype`)
  dispatch({ type: SET_ISSUE_TYPES, payload: response.data })
}

export const setTransition = issueId => async dispatch => {
  const axiosGetTransition = getAxiosInstance()
  const response = await axiosGetTransition.get(`/issue/${issueId}/transitions`)
  dispatch({ type: SET_TRANSITION, payload: response.data, issueId })
}

export const setAssignees = projectId => async dispatch => {
  const axiosGetAssignees = getAxiosInstance()
  const response = await axiosGetAssignees.get(`/user/assignable/search?project=${projectId}`)
  dispatch({ type: SET_ASSIGNEES, payload: response.data })
}

export const setPriorities = projectId => async dispatch => {
  const axiosGetPriorities = getAxiosInstance()
  const response = await axiosGetPriorities.get(`/priority`)
  dispatch({ type: SET_PRIORITIES, payload: response.data })
}

export const setActiveFilters = filterStack => {
  return {
    type: ACTIVE_FILTERS,
    payload: { filterStack },
  }
}
// export const setUserGroups = () => async (dispatch) => {
//   const axiosGetGroups = getAxiosInstance();
//   const response = await axiosGetGroups.get("/group");
//   console.log(response)

// }

export const setEmptyRows = emptyRows => {
  return {
    type: SET_EMPTY_ROWS,
    payload: emptyRows,
  }
}

export const setRiskRangeLow = riskRange => {
  return {
    type: SET_RISK_RANGE_LOW,
    payload: riskRange,
  }
}

export const setRiskRangeHigh = riskRange => {
  return {
    type: SET_RISK_RANGE_HIGH,
    payload: riskRange,
  }
}

export const setBudget = budget => {
  return {
    type: SET_BUDGET,
    payload: budget,
  }
}

export const setHourlyRate = hourlyRate => {
  return {
    type: SET_HOURLY_RATE,
    payload: hourlyRate,
  }
}
export const setDiscount = discount => {
  return {
    type: SET_DISCOUNT,
    payload: discount,
  }
}

export const setDiscountText = discountText => {
  return {
    type: SET_DISCOUNT_TEXT,
    payload: discountText,
  }
}

export const setDiscountTitle = discountTitle => {
  return {
    type: SET_DISCOUNT_TITLE,
    payload: discountTitle,
  }
}

export const refreshAuthLogic = async failedRequest => {
  const refreshToken = localStorage.getItem('RefreshToken')
  console.log('Je krijgt een nieuw token van de refresh!')
  const userCode = 1

  const axiosInstance = axios.create({
    baseURL: 'https://auth.atlassian.com',
    headers: { 'Content-type': 'application/json' },
  })

  await axiosInstance
    .post('/oauth/token', {
      grant_type: 'refresh_token',
      client_id: clientId,
      client_secret: clientSecret,
      refresh_token: refreshToken,
    })
    .then(tokenRefreshResponse => {
      const userToken = tokenRefreshResponse.data.access_token
      localStorage.setItem('AuthToken', userToken)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + userToken
      store.dispatch({ type: SIGN_IN, payload: { userCode, userToken, refreshToken } })

      return Promise.resolve()
    })
}

export const getAxiosInstance = () => {
  authToken = localStorage.getItem('AuthToken')

  const axiosInstance = axios.create({
    baseURL: `https://api.atlassian.com/ex/jira/${cloudId}/rest/api/3`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, { statusCodes: [401, 403] })
  return axiosInstance
}
