import React from 'react'
import PageHeaderClass from '../components/PageHeader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ContentWrapper from '../components/ContentWrapper'
import { HotTable } from '@handsontable/react'
import { updateIssueEstimation, updateIssue } from '../api/JiraPost'
import { setIssues } from '../actions'
import ComponentSelector from '../components/ComponentSelector'
import VersionSelector from '../components/VersionSelector'
import { BallBeat } from 'react-pure-loaders'
import history from '../history'

class DraaiTabelPage extends React.Component {
  constructor(props) {
    super(props)
    this.hotTableComponent = React.createRef()
    this.state = {
      nestedTableData: [],
      appIsGettingData: 0,
    }
  }

  componentDidMount() {
    if (this.props.project.projectSelected) {
      this.setState(prevState => {
        return { appIsGettingData: prevState.appIsGettingData + 1 }
      })
      this.props.setIssues(this.props.project.projectSelected.projectId).then(() => {
        this.setState(prevState => {
          return { appIsGettingData: prevState.appIsGettingData - 1 }
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    const selectedComponentId = this.props.components.componentSelected
      ? this.props.components.componentSelected.componentId
      : ''
    const selectedVersionId = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : ''
    const prevPropComponentId = prevProps.components.componentSelected
      ? prevProps.components.componentSelected.componentId
      : ''
    const prevPropVersionId = prevProps.versions.versionSelected ? prevProps.versions.versionSelected.versionId : ''

    if (selectedComponentId || selectedComponentId === '' || selectedVersionId || selectedVersionId === '') {
      if (prevPropComponentId !== selectedComponentId || prevPropVersionId !== selectedVersionId) {
        this.setState({ nestedTableData: this.items(this.props.issue) })
      }
    }

    if (JSON.stringify(prevProps.issue) !== JSON.stringify(this.props.issue)) {
      const itemsContent = this.items(this.props.issue)

      if (itemsContent.length > 0) {
        this.setState({ nestedTableData: itemsContent })
      }
    }
    if (this.hotTableComponent.current) {
      this.hotTableComponent.current.hotInstance.getPlugin('nestedRows').collapsingUI.collapseAll()
    }
  }

  items = issues => {
    let epics = issues.issueList.issues.filter(issue => {
      return issue.fields.issuetype.name === 'Epic'
    })
    let itemsContent = epics.map(epic => {
      const childrenOfEpic = this.getChildrenOfEpic(epic, issues)

      const itemContent = {
        Epic: epic.fields.customfield_10009,
        Issue: childrenOfEpic.length,
        EstimationHigh: epic.fields.customfield_11851 !== null ? epic.fields.customfield_11851 : null,
        EstimationLow: epic.fields.customfield_11850 !== null ? epic.fields.customfield_11850 : null,
        IssueKey: epic.key
          ? `<div class=""><a class="issue-key" href="https://hoproj.atlassian.net/browse/${epic.key}" target="_blank">${epic.key}</a></div>`
          : '',
        Version: epic.fields.fixVersions.length ? epic.fields.fixVersions[0].name : null,
        Component: epic.fields.components.length ? epic.fields.components[0].name : null,
        __children: childrenOfEpic,
      }
      if (itemContent.Issue) {
        return itemContent
      }
    })
    return itemsContent.filter(item => {
      return item !== undefined
    })
  }

  getChildrenOfEpic = (epic, issues) => {
    const selectedComponent = this.props.components.componentSelected
      ? this.props.components.componentSelected.componentId
      : false
    const selectedVersion = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : false

    let matchedIssues = issues.issueList.issues.filter(issue => {
      const issueChild = issue.fields.customfield_10008 === epic.key

      if (!issueChild) {
        return false
      }

      if (!selectedComponent && !selectedVersion) {
        //when no component or version is selected
        return issueChild
      }

      const issueHasComponent = !!issue.fields.components.length //does the epic have a component?
      const issueHasVersion = !!issue.fields.fixVersions.length //does the epic have a version?

      //if something is selected and the issue does not have that attribute
      if ((selectedComponent && !issueHasComponent) || (selectedVersion && !issueHasVersion)) {
        return false
      }

      // Is selected component && is selected version

      if (selectedComponent && selectedVersion) {
        return (
          selectedComponent &&
          issue.fields.components[0].id === selectedComponent &&
          selectedVersion &&
          issue.fields.fixVersions[0].id === selectedVersion
        )
      } else {
        return (
          (selectedComponent && issue.fields.components[0].id === selectedComponent) ||
          (selectedVersion && issue.fields.fixVersions[0].id === selectedVersion)
        )
      }
    })

    return matchedIssues.map(issue => {
      return {
        Issue: issue.fields.summary,
        EstimationHigh: issue.fields.customfield_11851 ? issue.fields.customfield_11851 : '',
        EstimationLow: issue.fields.customfield_11850 ? issue.fields.customfield_11850 : '',
        IssueKey: issue.key
          ? `<div class=""><a class="issue-key" href="https://hoproj.atlassian.net/browse/${issue.key}" target="_blank">${issue.key}</a></div>`
          : '',
        Version: issue.fields.fixVersions.length ? issue.fields.fixVersions[0].name : null,
        Component: issue.fields.components.length ? issue.fields.components[0].name : null,
      }
    })
  }

  afterChange = changes => {
    if (changes === null) {
    } else {
      changes.map(change => {
        let rowIndex = change[0]
        let column = change[1]
        let oldValue = change[2]
        let newValue = change[3]
        let issueId
        let rowData = this.hotTableComponent.current.hotInstance.getDataAtRow(rowIndex)

        if (rowData[4]) {
          //alleen issueid pakken als de issue al is aangemaakt
          let domElement = document.createRange().createContextualFragment(rowData[4])
          if (domElement.children && domElement.children[0]) {
            issueId = domElement.children[0].textContent
          }
        }

        if ((newValue === '' && oldValue === null) || (newValue === '' && oldValue === '')) {
          return null
        } else if (
          (newValue === '' || newValue === null || newValue === '0') &&
          (oldValue !== 0 || oldValue !== '' || oldValue !== null)
        ) {
          this.generalUpdateIssue(issueId, rowData, column)
        } else if (
          newValue !== '' &&
          oldValue !== newValue &&
          (oldValue === null || oldValue === undefined || oldValue === '')
        ) {
          this.generalUpdateIssue(issueId, rowData, column)
        } else if (newValue !== '' && oldValue !== newValue && oldValue !== null) {
          this.generalUpdateIssue(issueId, rowData, column)
        }
        return null
      })
    }
  }

  generalUpdateIssue = (issueId, rowData, column) => {
    if (column === 'EstimationLow' || column === 'EstimationHigh') {
      updateIssueEstimation(issueId, rowData[2], rowData[3])
    } else if (column === 'Issue') {
      updateIssue(issueId, rowData[1], null, null, null, null, null, 'issue')
    }
  }

  render() {
    let loaderText = ''
    if (this.state.appIsGettingData > 0) {
      loaderText = <p>The app is currently getting data</p>
    }
    if (!this.props.auth.isSignedIn) {
      history.push('/')
      return (
        <ContentWrapper>
          <BallBeat color={'#123abc'} loading />
        </ContentWrapper>
      )
    } else {
      return (
        <ContentWrapper>
          <PageHeaderClass
            page='draaitabel'
            title={this.props.project.projectList[this.props.project.projectSelected.projectId].name}
            projectId={this.props.project.projectSelected.projectId}
          />
          <BallBeat color={'#123abc'} loading={this.state.appIsGettingData} />
          {loaderText}
          <div className='selector-wrapper'>
            <ComponentSelector error={true} />
            <VersionSelector error={true} />
          </div>
          <br />
          <div className='hot-container'>
            <HotTable
              data={this.state.nestedTableData}
              ref={this.hotTableComponent}
              renderAllRows={true}
              nestedRows={true}
              columns={[
                {
                  data: 'Epic',
                  editor: false,
                },
                {
                  data: 'Issue',
                },
                {
                  data: 'EstimationHigh',
                  editor: 'text',
                },
                {
                  data: 'EstimationLow',
                  editor: 'text',
                },
                {
                  data: 'IssueKey',
                  editor: false,
                  renderer: 'html',
                },
                {
                  data: 'Version',
                  editor: 'text',
                },
                {
                  data: 'Component',
                  editor: 'text',
                },
              ]}
              colWidths={index => {
                return this.getCalculatedColWidth(index)
              }}
              rowHeaders={true}
              manualColumnResize={true}
              colHeaders={['Epic', 'Issue', 'Est. low (h)', 'Est. high (h)', 'Issue Key', 'Versions', 'Components']}
              licenseKey={'non-commercial-and-evaluation'}
              afterChange={changes => {
                this.afterChange(changes)
              }}
            />
          </div>
        </ContentWrapper>
      )
    }
  }

  getCalculatedColWidth = index => {
    let fullWidth = document.getElementsByClassName('hot-container')[0].clientWidth
    let tableFieldWidth = fullWidth - 90
    let width = undefined
    switch (index) {
      case 0:
        width = (10 * tableFieldWidth) / 100
        if (width < 120) {
          width = 120
        }
        break
      case 1:
        width = (20 * tableFieldWidth) / 100
        break
      case 2:
        width = (10 * tableFieldWidth) / 100
        if (width < 30) {
          width = 30
        }
        break
      case 3:
        width = (10 * tableFieldWidth) / 100
        if (width < 30) {
          width = 30
        }
        break
      case 4:
        width = (10 * tableFieldWidth) / 100
        break
      case 5:
        width = (10 * tableFieldWidth) / 100
        break
      case 6:
        width = (20 * tableFieldWidth) / 100
        break
      default:
        break
    }
    return width
  }
}

const mapStateToProps = state => {
  return {
    project: state.projects,
    issue: state.issue,
    components: state.component,
    versions: state.versions,
    auth: state.auth,
  }
}

export default withRouter(connect(mapStateToProps, { setIssues })(DraaiTabelPage))
