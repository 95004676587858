import React from 'react'
import '../css/LoginPage.css'
import { clientId, scope, callback_url } from '../jiraConfig'
import Button from '@atlaskit/button'

class LoginPage extends React.Component {
  handleClick = () => {
    return (window.location.href =
      `https://auth.atlassian.com/authorize?` +
      `&audience=api.atlassian.com` +
      `&client_id=${clientId}` +
      `&scope=${scope}` +
      `&redirect_uri=${callback_url}` +
      `&state=$1` +
      `&response_type=code` +
      `&prompt=consent`)
  }

  render() {
    const loginContainerStyle = {
      position: 'absolute',
      top: '20vh',
      left: 'calc(53vw - 70px)',
      width: '70px',
    }
    return (
      <div className='login-container' style={loginContainerStyle}>
        <Button className='login-button' onClick={() => this.handleClick()}>
          Login
        </Button>
      </div>
    )
  }
}

export default LoginPage
