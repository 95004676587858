import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { setComponentSelected, setComponents } from '../actions'
import '../css/Selector.css'

class ComponentSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { options: [] }
  }

  componentDidMount() {
    this.setState({ options: this.getComponentsFormatted() })
  }

  getComponentsFormatted = () => {
    const componentList = this.props.components.componentList.map(component => {
      return { key: component.id, text: component.name, value: component.id }
    })
    componentList.unshift({ key: '', text: '', value: '' })
    return componentList
  }

  render() {
    const componentId = this.props.components.componentSelected
      ? this.props.components.componentSelected.componentId
      : ''
    return (
      <div className='dropdown-component-wrapper'>
        <Dropdown
          className='dropdown-component'
          placeholder='Componenten'
          defaultValue={componentId}
          fluid
          selection
          options={this.state.options}
          onChange={(e, data) => {
            this.props.setComponentSelected(data.value)
          }}
          error={!this.props.error}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    project: state.projects,
    issue: state.issue,
    components: state.component,
  }
}

export default connect(mapStateToProps, { setComponentSelected, setComponents })(ComponentSelector)
