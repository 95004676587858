import React from 'react'
import { connect } from 'react-redux'
import { signIn, setProjects } from '../actions'
import axios from 'axios'
import { callback_url, clientSecret, clientId } from '../jiraConfig'
import { BallBeat } from 'react-pure-loaders'
import ContentWrapper from './ContentWrapper'

class JiraAuth extends React.Component {
  authCodeForAccessToken = () => {
    //gebruik je url code om een token te krijgen van jira

    const axiosInstance = axios.create({
      baseURL: 'https://auth.atlassian.com',
      headers: { 'Content-type': 'application/json' },
    })

    axiosInstance
      .post('/oauth/token', {
        grant_type: 'authorization_code',
        client_id: clientId,
        client_secret: clientSecret,
        code: this.getUrlParams(),
        redirect_uri: callback_url,
      })
      .then(response => {
        this.props.signIn(this.getUrlParams(), response.data.access_token, response.data.refresh_token) //actioncreator
      })
      .catch(error => console.log('error tijdens het fetchen van token' + error))
  }

  getUrlParams() {
    const url = new URL(window.location.href)
    return url.searchParams.get('code')
  }

  render() {
    if (!this.props.isSignedIn) {
      //check of gebruiken al een token heeft
      this.authCodeForAccessToken()
    }
    return (
      <ContentWrapper>
        <BallBeat color={'#123abc'} loading />
      </ContentWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    projectList: state.projects.projectList,
    authToken: state.auth.userToken,
  }
}

export default connect(mapStateToProps, { signIn, setProjects })(JiraAuth)
