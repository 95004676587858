import { SET_LANGUAGE_SELECTED } from '../actions/types'

const INITIAL_STATE = {
  languageSelected: null,
}

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE_SELECTED:
      return { ...state, languageSelected: action.payload }
    default:
      return state
  }
}
export default languageReducer
