import { SET_ISSUE_TYPES } from '../actions/types'

const INITIAL_STATE = {
  issueTypeList: null,
}

const issueTypeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ISSUE_TYPES:
      return { ...state, issueTypeList: action.payload }
    default:
      return state
  }
}
export default issueTypeReducer
