import { SIGN_IN, SIGN_OUT } from '../actions/types'

const INITIAL_STATE = {
  isSignedIn: null,
  userCode: null,
  userToken: null,
  refreshToken: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        userCode: action.payload.userCode,
        userToken: action.payload.userToken,
        refreshToken: action.payload.refreshToken,
      }
    case SIGN_OUT:
      return { ...state, isSignedIn: false, userCode: null, userToken: null }
    default:
      return state
  }
}
