import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { setLanguageSelected } from '../actions'
import '../css/Selector.css'

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { options: [] }
  }

  render() {
    const language = this.props.language?.languageSelected ? this.props.language?.languageSelected.language : ''

    return (
      <div className='dropdown-component-wrapper'>
        <Dropdown
          className='dropdown-component'
          placeholder='Taal'
          defaultValue={language}
          fluid
          selection
          options={[
            { key: 'NL', text: 'Nederlands', value: 'NL' },
            { key: 'EN', text: 'English', value: 'EN' },
          ]}
          onChange={(e, data) => {
            this.props.setLanguageSelected(data.value)
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps, { setLanguageSelected })(LanguageSelector)
