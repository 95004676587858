import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Router, Route } from 'react-router-dom'
import history from '../history.js'
import App from './App'
import HomePage from '../pages/HomePage'
import IssuePage from '../pages/IssuePage'
import DraaiTabelPage from '../pages/DraaiTabelPage'
import SimulatiePage from '../pages/SimulatiePage'

export default class MainRouter extends Component {
  constructor() {
    super()
    this.state = {
      navOpenState: {
        isOpen: true,
        width: 304,
      },
    }
  }

  getChildContext() {
    return {
      navOpenState: this.state.navOpenState,
    }
  }

  appWithPersistentNav = () => props => <App onNavResize={this.onNavResize} {...props} />

  onNavResize = navOpenState => {
    this.setState({
      navOpenState,
    })
  }

  render() {
    return (
      <Router history={history}>
        <App>
          <Route path='/' exact component={HomePage} />
          <Route path='/projects/:id' exact component={IssuePage} />
          <Route path={'/projects/:id/initiative/:id'} exact component={IssuePage} />
          <Route path={'/projects/:id/draaitabel'} exact component={DraaiTabelPage} />
          <Route path={'/projects/:id/simulatie'} exact component={SimulatiePage} />
        </App>
      </Router>
    )
  }
}

MainRouter.childContextTypes = {
  navOpenState: PropTypes.object,
}
