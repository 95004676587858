import { combineReducers } from 'redux'
import authReducer from './authReducer'
import projectReducer from './projectReducer'
import componentReducer from './componentReducer'
import issueReducer from './issueReducer'
import versionReducer from './versionReducer'
import issueTypeReducer from './issueTypeReducer'
import transitionReducer from './transitionReducer'
import assigneeReducer from './assigneeReducer'
import priorityReducer from './priorityReducer'
import filterReducer from './filterReducer'
import emptyRowsReducer from './emptyRowsReducer'
import riskRangeReducer from './riskRangeReducer'
import budgetReducer from './budgetReducer'
import hourlyRateReducer from './hourlyRateReducer'
import languageReducer from './languageReducer'
import discountReducer from './discountReducer'

export default combineReducers({
  auth: authReducer,
  projects: projectReducer,
  component: componentReducer,
  issue: issueReducer,
  versions: versionReducer,
  issueTypes: issueTypeReducer,
  transition: transitionReducer,
  assignee: assigneeReducer,
  priority: priorityReducer,
  filter: filterReducer,
  emptyRows: emptyRowsReducer,
  riskRange: riskRangeReducer,
  budget: budgetReducer,
  hourlyRate: hourlyRateReducer,
  language: languageReducer,
  discount: discountReducer,
})
