import React from 'react'
import PageHeaderClass from '../components/PageHeader'
import ContentWrapperSimulatie from '../components/ContentWrapperSimulatie'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import FormBlock from '../components/FormBlock'
import { Grid } from 'semantic-ui-react'
import ContentWrapper from '../components/ContentWrapper'
import '../css/SimulatiePage.css'
import {
  setRiskRangeLow,
  setIssues,
  setRiskRangeHigh,
  setBudget,
  setHourlyRate,
  setComponents,
  setComponentSelected,
  setVersions,
  setDiscount,
  setDiscountText,
  setDiscountTitle,
} from '../actions'
import '../css/Selector.css'
import GetSimulatedResult from '../components/GetSimulatedResult'
import '../css/SimulatieResultPage.css'
import { BallBeat } from 'react-pure-loaders'
import history from '../history'

class SimulatiePage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      cost: true,
      bestCase: true,
      worstCase: true,
      budget: true,
      epicsAreEstimated: false,
      epicsWithoutEstimation: [],
      appIsGettingData: 0,
      discountText: String,
    }
  }

  componentDidMount = () => {
    this.setState(prevState => {
      return { appIsGettingData: prevState.appIsGettingData + 1 }
    })

    const selectedComponentId = this.props.componentSelected ? this.props.componentSelected.componentId : ''
    const selectedVersionId = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : ''
    const selectedProjectId = this.props.project.projectSelected ? this.props.project.projectSelected.projectId : ''

    this.props.setComponents(selectedProjectId)
    this.props.setVersions(selectedProjectId)
    this.props.setIssues(selectedProjectId).then(() => {
      if (selectedComponentId || (selectedComponentId === '' && selectedVersionId) || selectedVersionId === '') {
        this.setState({
          epicsAreEstimated: this.isEveryEpicEstimated(selectedComponentId, selectedVersionId),
        })
        this.setState({
          epicsWithoutEstimation: this.getEpicsWithoutEstimations(selectedComponentId, selectedVersionId),
        })
      }
      this.setState(prevState => {
        return { appIsGettingData: prevState.appIsGettingData - 1 }
      })
    })
  }

  componentDidUpdate(prevProps) {
    const selectedComponentId = this.props.componentSelected ? this.props.componentSelected.componentId : ''
    const selectedVersionId = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : ''
    const prevPropComponentId = prevProps.componentSelected ? prevProps.componentSelected.componentId : ''
    const prevPropVersionId = prevProps.versions.versionSelected ? prevProps.versions.versionSelected.versionId : ''

    if (selectedComponentId || selectedComponentId === '' || selectedVersionId || selectedVersionId === '') {
      if (prevPropComponentId !== selectedComponentId || prevPropVersionId !== selectedVersionId) {
        this.setState({
          epicsAreEstimated: this.isEveryEpicEstimated(selectedComponentId, selectedVersionId),
        })
        this.setState({
          epicsWithoutEstimation: this.getEpicsWithoutEstimations(selectedComponentId, selectedVersionId),
        })
      }
    }
  }

  isEveryEpicEstimated = (selectedComponentId, selectedVersionId) => {
    const epics = this.getEpicsForComponentOrVersion(this.props.issue)
    const withEsts = epics.filter(epic => {
      return (
        epic.EstimationHigh !== undefined &&
        epic.EstimationHigh !== '' &&
        epic.EstimationHigh !== null &&
        epic.EstimationLow !== undefined &&
        epic.EstimationLow !== '' &&
        epic.EstimationLow !== null
      )
    })

    return JSON.stringify(epics) === JSON.stringify(withEsts)
  }

  getEpicsWithoutEstimations = (selectedComponentId, selectedVersionId) => {
    const epics = this.getEpicsForComponentOrVersion(this.props.issue)
    const withoutEsts = epics.filter(epic => {
      return (
        (epic.EstimationHigh === undefined || epic.EstimationHigh === '' || epic.EstimationHigh === null) &&
        (epic.EstimationLow === undefined || epic.EstimationLow === '' || epic.EstimationLow === null)
      )
    })

    return withoutEsts
  }

  getEpicsForComponentOrVersion = issues => {
    let epics = issues.issueList.issues.filter(issue => {
      return issue.fields.issuetype.name === 'Epic'
    })
    let itemsContent = epics.map(epic => {
      const childrenOfEpic = this.getChildrenOfEpic(epic, issues)

      const itemContent = {
        Epic: epic.fields.customfield_10009,
        Issue: childrenOfEpic.length,
        EstimationHigh: epic.fields.customfield_11851,
        EstimationLow: epic.fields.customfield_11850,
        __children: childrenOfEpic,
      }
      if (itemContent.Issue) {
        return itemContent
      }
    })
    return itemsContent.filter(item => {
      return item !== undefined
    })
  }

  getChildrenOfEpic = (epic, issues) => {
    const selectedComponent = this.props.componentSelected ? this.props.componentSelected.componentId : false
    const selectedVersion = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : false

    let matchedIssues = issues.issueList.issues.filter(issue => {
      const issueChild = issue.fields.customfield_10008 === epic.key

      if (!issueChild) {
        return false
      }

      if (!selectedComponent && !selectedVersion) {
        //when no component or version is selected
        return issueChild
      }

      const issueHasComponent = !!issue.fields.components.length //does the epic have a component?
      const issueHasVersion = !!issue.fields.fixVersions.length //does the epic have a version?

      //if something is selected and the issue does not have that attribute
      if ((selectedComponent && !issueHasComponent) || (selectedVersion && !issueHasVersion)) {
        return false
      }

      // Is selected component && is selected version

      if (selectedComponent && selectedVersion) {
        return (
          selectedComponent &&
          issue.fields.components[0].id === selectedComponent &&
          selectedVersion &&
          issue.fields.fixVersions[0].id === selectedVersion
        )
      } else {
        return (
          (selectedComponent && issue.fields.components[0].id === selectedComponent) ||
          (selectedVersion && issue.fields.fixVersions[0].id === selectedVersion)
        )
      }
    })

    return matchedIssues.map(issue => {
      return {
        Issue: issue.fields.summary,
        EstimationHigh: issue.fields.customfield_11851 ? issue.fields.customfield_11851 : '',
        EstimationLow: issue.fields.customfield_11850 ? issue.fields.customfield_11850 : '',
        IssueKey: issue.key
          ? `<div class=""><a class="issue-key" href="https://hoproj.atlassian.net/browse/${issue.key}" target="_blank">${issue.key}</a></div>`
          : '',
        Version: issue.fields.fixVersions.length ? issue.fields.fixVersions[0].name : null,
        Component: issue.fields.components.length ? issue.fields.components[0].name : null,
      }
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    const inputFieldDiscountText = document.getElementsByTagName('textarea')
    const discountText = inputFieldDiscountText[0].value

    this.setState({ discountText })
    this.props.setDiscountText(discountText)

    let inputFields = document.getElementsByTagName('input')
    let functionState = []
    Object.values(inputFields).map(field => {
      let fieldValueInt = field.value.replace(/,/g, '.')
      if (!isNaN(fieldValueInt) && fieldValueInt !== '') {
        switch (field.className) {
          case 'cost':
            this.props.setHourlyRate(fieldValueInt)
            this.setState({ cost: true })
            break
          case 'dropdown':
            //select action thingy
            break
          case 'riskRangeLow':
            this.setState({ bestCase: true })
            this.props.setRiskRangeLow(fieldValueInt)
            break
          case 'riskRangeHigh':
            this.setState({ worstCase: true })
            this.props.setRiskRangeHigh(fieldValueInt)
            break
          case 'budget':
            this.props.setBudget(fieldValueInt)
            this.setState({ budget: true })
            break
          case 'discount':
            this.props.setDiscount(fieldValueInt)
            break
          case 'discount-title':
            this.props.setDiscountTitle(fieldValueInt)
            break

          default:
            break
        }
      } else {
        switch (field.className) {
          case 'discount-title':
            this.props.setDiscountTitle(fieldValueInt)
            break
          case 'cost':
            functionState.push(false)
            this.setState({ cost: false })
            break
          case 'dropdown':
            break
          case 'riskRangeLow':
            functionState.push(false)
            this.setState({ bestCase: false })
            break
          case 'riskRangeHigh':
            functionState.push(false)
            this.setState({ worstCase: false })
            break
          default:
            break
        }
      }
      return null
    })
  }

  render() {
    let loaderText = ''
    if (this.state.appIsGettingData > 0) {
      loaderText = <p>The app is currently getting data</p>
    }
    if (!this.props.auth.isSignedIn) {
      history.push('/')
      return (
        <ContentWrapper>
          <BallBeat color={'#123abc'} loading />
        </ContentWrapper>
      )
    } else {
      return (
        <div>
          <div className='simulation-config-wrapper'>
            <ContentWrapper>
              <PageHeaderClass
                page='simulatie'
                title={this.props.project.projectList[this.props.project.projectSelected.projectId].name}
                projectId={this.props.project.projectSelected.projectId}
              />
              <BallBeat color={'#123abc'} loading={this.state.appIsGettingData} />
              {loaderText}
            </ContentWrapper>
            <ContentWrapperSimulatie>
              <Grid columns={1}>
                <form onSubmit={this.handleSubmit} className='ui form'>
                  <Grid.Row className='simulatie-row'>
                    <FormBlock
                      blockNumber={0}
                      title={'Selecteer component en/of versie'}
                      errorState={this.state.epicsAreEstimated}
                      epicsWithoutEstimations={this.state.epicsWithoutEstimation}
                    />
                  </Grid.Row>
                  <Grid.Row className='simulatie-row'>
                    <FormBlock
                      blockNumber={1}
                      defaultValue={this.props.hourlyRate.hourlyRate}
                      title={'Team Cost'}
                      errorState={this.state.cost}
                    />
                  </Grid.Row>
                  <Grid.Row className='simulatie-row'>
                    <FormBlock
                      blockNumber={2}
                      title={'Risk Factor'}
                      defaultValue={this.props.riskRange}
                      errorStateBest={this.state.bestCase}
                      errorStateWorst={this.state.worstCase}
                    />
                  </Grid.Row>
                  <Grid.Row className='simulatie-row'>
                    <FormBlock blockNumber={3} title={'Taal'} defaultValue={this.props.language} />
                  </Grid.Row>
                  <Grid.Row className='simulatie-row'>
                    <FormBlock blockNumber={4} defaultValue={this.props.discount} title={'Korting'} />
                  </Grid.Row>
                  <Grid.Row>
                    <button type='submit' className='ui button primary'>
                      Simuleer resultaat
                    </button>
                  </Grid.Row>
                </form>
              </Grid>
            </ContentWrapperSimulatie>
          </div>
          <ContentWrapper>
            <GetSimulatedResult />
          </ContentWrapper>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    project: state.projects,
    issue: state.issue,
    riskRange: state.riskRange,
    budget: state.budget,
    hourlyRate: state.hourlyRate,
    componentSelected: state.component.componentSelected,
    versions: state.versions,
    auth: state.auth,
    discount: state.discount,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setRiskRangeLow,
    setRiskRangeHigh,
    setBudget,
    setHourlyRate,
    setComponentSelected,
    setComponents,
    setIssues,
    setVersions,
    setDiscount,
    setDiscountText,
    setDiscountTitle,
  })(SimulatiePage),
)
