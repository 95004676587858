import React from 'react'
import { Header } from 'semantic-ui-react'
import ComponentSelector from './ComponentSelector'
import VersionSelector from './VersionSelector'
import LanguageSelector from './LanguageSelector'

const FormBlock = props => {
  const TextFirstBlock = () => {
    return <span></span>
  }

  const TextSecondBlock = () => {
    return <span></span>
  }

  let textValue

  switch (props.blockNumber) {
    case 1:
      textValue = <TextFirstBlock />
      break
    case 2:
      textValue = <TextSecondBlock />
      break
    default:
      break
  }

  const FormZeroBlock = () => {
    const errorText = !props.errorState ? 'Vul eerst alle epic estimations in!' : ''
    let errorEpics
    if (props.epicsWithoutEstimations.length > 0) {
      errorEpics = (
        <ul>
          {props.epicsWithoutEstimations.map((epic, key) => {
            return <li key={key}>{epic.Epic}</li>
          })}
        </ul>
      )
    }
    return (
      <div>
        <div className='equal width fields'>
          <ComponentSelector error={props.errorState} />
          <VersionSelector error={props.errorState} />
        </div>
        {errorText}
        {errorEpics}
        <div className='ui hidden divider'></div>
      </div>
    )
  }

  const FormFirstBlock = () => {
    let errorState = props.errorState ? '' : 'error'
    return (
      <div>
        <div className='equal width fields'>
          <div className={'field ' + errorState}>
            <label>Hourly Cost(€)</label>
            <input className='cost' defaultValue={props.defaultValue} />
          </div>
        </div>
        {textValue}
        <div className='ui hidden divider'></div>
      </div>
    )
  }

  const FormSecondBlock = () => {
    let errorStateBest = props.errorStateBest ? '' : 'error'
    let errorStateWorst = props.errorStateWorst ? '' : 'error'
    return (
      <div>
        <div className='equal width fields'>
          <div className={'field ' + errorStateBest}>
            <label>Best Case</label>
            <input className='riskRangeLow' defaultValue={props.defaultValue.riskRangeLow} />
          </div>
          <div className={'field ' + errorStateWorst}>
            <label>Worst Case</label>
            <input className='riskRangeHigh' defaultValue={props.defaultValue.riskRangeHigh} />
          </div>
        </div>
        {textValue}
        <div className='ui hidden divider'></div>
      </div>
    )
  }

  const FormThirdBlock = () => {
    return (
      <div>
        <div className='equal width fields'>
          <div className={'field '}>
            <LanguageSelector />
          </div>
        </div>
        {textValue}
        <div className='ui hidden divider'></div>
      </div>
    )
  }

  const FormFourthBlock = () => {
    return (
      <div>
        <div>
          <div className={'field '}>
            <label>Percentage korting</label>
            <input className='discount' defaultValue={props.defaultValue.discount} />
          </div>
          <div className={'field '}>
            <label>Korting verkoop titel</label>
            <input className='discount-title' defaultValue={props.defaultValue.discountTitle} />
          </div>
          <div className={'field '}>
            <label>Korting verkoop tekst</label>
            <textarea className='discount-text' defaultValue={props.defaultValue.discountText} />
          </div>
        </div>
        {textValue}
        <div className='ui hidden divider'></div>
      </div>
    )
  }

  let formFields

  switch (props.blockNumber) {
    case 0:
      formFields = <FormZeroBlock />
      break
    case 1:
      formFields = <FormFirstBlock />
      break
    case 2:
      formFields = <FormSecondBlock />
      break
    case 3:
      formFields = <FormThirdBlock />
      break
    case 4:
      formFields = <FormFourthBlock />
      break
    default:
      break
  }

  return (
    <div style={{ backgroundColor: '#fafafa', border: '1px #dddddd solid', padding: '15px', width: '800px' }}>
      <div className='ui container'>
        <Header as='h3'>{props.title}</Header>
        {formFields}
      </div>
    </div>
  )
}
export default FormBlock
