import { SET_ISSUES } from '../actions/types'

const INITIAL_STATE = {
  issueList: null,
}

const issueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ISSUES:
      // if(action.payload){
      //   action.payload.issues.sort(function (a,b){
      //     var x = a.fields.customfield_10600
      //     var y = b.fields.customfield_10600
      //     if (x < y) {return -1}
      //     if (y < x) {return 1}
      //     return 0
      //   })
      // }
      return { ...state, issueList: action.payload }
    default:
      return state
  }
}
export default issueReducer
