import * as React from 'react'
import DynamicTable from '@atlaskit/dynamic-table'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ContentWrapper from '../components/ContentWrapper'
import { setProjectSelected, setProjects } from '../actions'
import SearchBar from './SearchBar'
import PageHeaderClass from './PageHeader'
import { BallBeat } from 'react-pure-loaders'

class ProjectTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = { projects: [] }
  }
  componentDidMount() {
    if (this.props.projectList) {
      this.handleCategoriesChange() //initial filter
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    if (this.props.projectList !== prevProps.projectList) {
      this.handleCategoriesChange()
    }
  }

  setProjectStateOrder = (recentProjects, projects) => {
    let newProjectList = { ...projects, ...recentProjects }

    const uniqueProjectList = Array.from(new Set(Object.values(newProjectList).map(a => a.id))).map(id => {
      return Object.values(newProjectList).find(a => a.id === id)
    })

    this.setState({ projects: uniqueProjectList })
  }

  ProjectToTable = projects =>
    projects.map(project => {
      if (project.projectCategory) {
        return {
          key: project.key,
          cells: [
            {
              content: (
                <div>
                  <Link onClick={() => this.props.setProjectSelected(project.id)} to={`/projects/${project.id}`}>
                    {project.name}
                  </Link>
                </div>
              ),
            },
            {
              content: project.key, //hier komt de cell content vandaan
            },
            {
              content: project.projectTypeKey, //hier komt de cell content vandaan
            },
            {
              content: project.lead.displayName, //hier komt de cell content vandaan
            },
            {
              content: project.projectCategory.name,
            },
          ],
        }
      } else {
        return {
          key: project.key,
          cells: [
            {
              content: (
                <div>
                  <Link onClick={() => this.props.setProjectSelected(project.id)} to={`/projects/${project.id}`}>
                    {project.name}
                  </Link>
                </div>
              ),
            },
            {
              content: project.key, //hier komt de cell content vandaan
            },
            {
              content: project.projectTypeKey, //hier komt de cell content vandaan
            },
            {
              content: project.lead.displayName, //hier komt de cell content vandaan
            },
            {
              content: '',
            },
          ],
        }
      }
    })

  handleSearchBar = searchValue => {
    let searchState = Object.values(this.props.projectList).filter(project => {
      return project.name.search(new RegExp(searchValue, 'i')) !== -1
    })
    this.setState({ projects: searchState })
    if (!searchValue) {
      this.handleCategoriesChange()
    }
  }

  handleCategoriesChange = e => {
    const categorieSelected = e ? e.target.textContent : 'Niet Gearchiveerd'

    let newProjectState =
      categorieSelected === 'Gearchiveerd'
        ? Object.values(this.props.projectList).filter(project => {
            return (project = project.projectCategory ? project.projectCategory.name === categorieSelected : null)
          })
        : Object.values(this.props.projectList).filter(project => {
            return (project = project.projectCategory ? project.projectCategory.name !== 'Gearchiveerd' : null)
          })

    this.setState({ projects: newProjectState })
    this.setProjectStateOrder(this.props.recentProjectList, newProjectState)
  }

  render() {
    if (!this.props.projectList) {
      return (
        <ContentWrapper>
          <BallBeat color={'#123abc'} loading />
        </ContentWrapper>
      )
    } else {
      return (
        <ContentWrapper>
          <PageHeaderClass page='homepage' />
          <SearchBar onChange={this.handleSearchBar.bind(this)} />
          <DynamicTable
            caption={''}
            head={{
              cells: [
                {
                  key: 'project',
                  content: 'Project', //header name
                  isSortable: true,
                },
                {
                  key: 'key',
                  content: 'Key', //header name
                  isSortable: true,
                },
                {
                  key: 'type',
                  content: 'Type', //header name
                  isSortable: true,
                },
                {
                  key: 'lead',
                  content: 'Lead', //header name
                  isSortable: true,
                },
                {
                  key: 'Category',
                  content: 'Category',
                  isSortable: true,
                },
              ],
            }}
            rows={this.ProjectToTable(Object.values(this.state.projects))} //format naar array voor de .map functie
            rowsPerPage={20}
            defaultPage={1}
            isFixedSize
            defaultSortOrder='ASC'
            defaultSortKey='project'
          />
        </ContentWrapper> //onlyactiveonindex stond hier
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    projectList: state.projects.projectList,
    recentProjectList: state.projects.recentProjectList,
  }
}

export default connect(mapStateToProps, { setProjectSelected, setProjects })(ProjectTable)
