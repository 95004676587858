import React from 'react'
import { connect } from 'react-redux'
import {
  setActiveFilters,
  setAssignees,
  setComponents,
  setEmptyRows,
  setIssues,
  setIssueTypes,
  setPriorities,
  setTransition,
  setVersions,
} from '../actions'
import {
  addEpicToIssueJira,
  bulkCreateEpic,
  bulkCreateIssue,
  deleteField,
  deleteIssue,
  postNewComponent,
  postNewEpic,
  postNewIssue,
  postNewVersion,
  updateIssue,
} from '../api/JiraPost'
import { withRouter } from 'react-router'
import ContentWrapper from './ContentWrapper'
import PageHeaderClass from './PageHeader'
import { BallBeat } from 'react-pure-loaders'
import { HotTable } from '@handsontable/react'
import Handsontable from 'handsontable'

class IssueHotTable extends React.Component {
  constructor(props) {
    super(props)
    this.debounceFn = this.debounceFn.bind(this)
    this.hotTableComponent = React.createRef()

    this.state = {
      appIsGettingData: 0,
      localEpics: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.state.localEpics) === JSON.stringify(nextState.localEpics)
  }

  getIssues = issues => {
    const data = issues.map(issue => {
      let epicIssueName

      if (issue.fields.issuetype.name === 'Epic') {
        return []
      }

      if (issue.fields.customfield_10008) {
        epicIssueName = issues.filter(epicIssue => {
          return epicIssue.key === issue.fields.customfield_10008
        })
      }

      const epicName =
        epicIssueName && epicIssueName.length > 0
          ? epicIssueName[0].fields.customfield_10009
          : issue.fields.customfield_10008
      const componentName = issue.fields.components[0] ? issue.fields.components[0].name : ''
      const version = issue.fields.fixVersions[0] ? issue.fields.fixVersions[0].name : ''
      const issueKey = `<div class=""><a class="issue-key" href="https://hoproj.atlassian.net/browse/${issue.key}" target="_blank">${issue.key}</a></div>`
      let issueDescription

      if (issue.fields.description !== null) {
        if (issue.fields.description.content.length > 0) {
          issueDescription = issue.fields.description.content[0].content[0]
            ? issue.fields.description.content[0].content[0].text
            : ''
        }
      }

      return [issue.fields.summary, epicName, version, componentName, issueKey, issueDescription]
    })

    return data.filter(item => {
      return item.length > 0
    })
  }

  onCellHover = (event, coords, TD) => {
    if (this.hotTableComponent.current && this.props.issue.issueList) {
      if ((coords.col === 1 || coords.col === 2 || coords.col === 3) && TD.innerText.length > 1 && coords.row > -1) {
        let iconEle = document.createElement('i')
        iconEle.className = 'icon external icon-external-link'
        let rowData = this.hotTableComponent.current.hotInstance.getDataAtRow(coords.row)
        let domElement = document.createRange().createContextualFragment(rowData[4])
        if (domElement.children && domElement.children[0]) {
          let issueId = domElement.children[0].textContent

          let issueObject = this.props.issue.issueList.issues.filter(issue => {
            return issue.key === issueId
          })
          switch (coords.col) {
            case 1:
              iconEle.id = issueObject[0] && issueObject[0].fields ? issueObject[0].fields.customfield_10008 : ''
              break
            case 2:
              iconEle.id =
                issueObject[0] && issueObject[0].fields && issueObject[0].fields.fixVersions[0]
                  ? issueObject[0].fields.fixVersions[0].id
                  : ''
              break
            case 3:
              iconEle.id =
                issueObject[0] && issueObject[0].fields && issueObject[0].fields.components[0]
                  ? issueObject[0].fields.components[0].name
                  : ''
              break
            default:
              break
          }
          TD.appendChild(iconEle)
        }
      }
    } else {
      console.log('geen table')
    }
  }
  onCellHoverOut = (event, coords, TD) => {
    if (coords.col === 1 || coords.col === 2 || coords.col === 3) {
      if (TD.childNodes) {
        Array.prototype.slice.call(TD.childNodes).map((child, i) => {
          if (child.className === 'icon external icon-external-link') {
            TD.removeChild(TD.childNodes[i])
          }
          return false
        })
      }
    }
  }

  // Event for `keydown` event. Add condition after delay of 200 ms which is counted from time of last pressed key.
  debounceFn = Handsontable.helper.debounce(function(colIndex, event) {
    let filtersPlugin = this.hotTableComponent.current.hotInstance.getPlugin('filters')
    filtersPlugin.removeConditions(colIndex)
    filtersPlugin.addCondition(colIndex, 'contains', [event.realTarget.value])
    filtersPlugin.filter()
  }, 400)

  addEventListeners = (input, colIndex) => {
    let debounce = this.debounceFn
    input.addEventListener('keydown', function(event) {
      debounce(colIndex, event)
    })
  }

  // Build elements which will be displayed in header.
  getInitializedElements = colIndex => {
    const div = document.createElement('div')
    const input = document.createElement('input')

    div.className = 'filterHeader'

    this.addEventListeners(input, colIndex)

    div.appendChild(input)

    return div
  }

  // Add elements to header on `afterGetColHeader` hook.
  addInput = (col, TH) => {
    // Hooks can return value other than number (for example `columnSorting` plugin use this).
    if (typeof col !== 'number') {
      return col
    }

    if (col >= 0 && TH.childElementCount < 2) {
      TH.appendChild(this.getInitializedElements(col))
    }
  }

  // Deselect column after click on input.
  doNotSelectColumn = (event, coords) => {
    if (coords.row === -1 && event.realTarget.nodeName === 'INPUT') {
      event.stopImmediatePropagation()
      this.hotTableComponent.current.hotInstance.deselectCell()
    }
  }
  getCalculatedColWidth = index => {
    let fullWidth = document.getElementsByClassName('hot-container')[0].clientWidth
    let tableFieldWidth = fullWidth - 61

    let width = undefined
    switch (index) {
      case 0:
        width = (44.5269 * tableFieldWidth) / 100
        break
      case 1:
        width = (10 * tableFieldWidth) / 100
        if (width < 120) {
          width = 120
        }
        break
      case 2:
        width = (3.7106 * tableFieldWidth) / 100
        if (width < 40) {
          width = 40
        }
        break
      case 3:
        width = (8.0706 * tableFieldWidth) / 100
        if (width < 120) {
          width = 120
        }
        break
      case 4:
        width = (5.2876 * tableFieldWidth) / 100
        if (width < 80) {
          width = 80
        }
        break
      case 5:
        width = (15 * tableFieldWidth) / 100
        if (width < 80) {
          width = 120
        }
        break
      default:
        break
    }
    return width
  }

  afterMouseDown = (mouseEvent, coords, td) => {
    const targetElement = mouseEvent.target
    if (targetElement) {
      if (mouseEvent.target.className === 'icon external icon-external-link') {
        let projectKey = this.props.project.projectList[this.props.project.projectSelected.projectId].key
        switch (coords.col) {
          case 1:
            window.open(`https://hoproj.atlassian.net/browse/${mouseEvent.realTarget.id}`, '_blank')
            break
          case 2:
            window.open(
              `https://hoproj.atlassian.net/projects/${projectKey}/versions/${mouseEvent.realTarget.id}`,
              '_blank',
            )
            break
          case 3:
            window.open(
              `https://hoproj.atlassian.net/issues/?jql=project%20%3D%20%22${projectKey}%22%20AND%20component%20%3D%20%22${mouseEvent.realTarget.id}%22`,
              '_blank',
            )
            break
          default:
            break
        }
      }
    }
  }

  beforeChange = (changes, source) => {
    if (source === 'CopyPaste.paste' && changes.length > 1) {
      //if pasted and more than 50 items are pasted
      if (changes[0][1] === 0) {
        //issue paste
        this.setState(prevState => {
          return { appIsGettingData: prevState.appIsGettingData + 1 }
        })
        bulkCreateIssue(changes, this.props.project.projectSelected.projectId)
          .then(() => {
            this.props.setIssues(this.props.project.projectSelected.projectId).then(() => {
              this.setState(prevState => {
                return { appIsGettingData: prevState.appIsGettingData - 1 }
              })
            })
          })
          .catch(error => {
            window.location.reload()
            // alert(error)
            // // eslint-disable-next-line no-restricted-globals
            // if (confirm('Please refresh the page and check if all the issues are correctly processed')) {
            //   window.location.reload()
            // } else {
            //   this.props.setIssues(this.props.project.projectSelected.projectId).then(() => {
            //     this.setState(prevState => {
            //       return { appIsGettingData: prevState.appIsGettingData - 1 }
            //     })
            //   })
            // }
          })
        return false
      }
      if (changes[0][1] === 1) {
        //epic paste

        const existingEpicsInJira = this.getAllEpics()
        const newEpics = this.removeDuplicateEpics(changes, existingEpicsInJira) //check duplicate epics vs changes

        if (newEpics.length > 0) {
          //if unique epics are pasted
          this.setState(prevState => {
            return { appIsGettingData: prevState.appIsGettingData + 1 }
          })
          bulkCreateEpic(newEpics, this.props.project.projectSelected.projectId)
            .then(response => {
              this.addEpicsToIssues(response.data.issues, newEpics, changes)
              this.props.setIssues(this.props.project.projectSelected.projectId).then(() => {
                this.setState(prevState => {
                  return { appIsGettingData: prevState.appIsGettingData - 1 }
                })
              })
            })
            .catch(error => {
              alert(error)
              //add epic keys to issues, first get the keys and then add them to the issues.
              //add epic keys to issues, first get the keys and then add them to the issues.
              //add epic keys to issues, first get the keys and then add them to the issues.
              //add epic keys to issues, first get the keys and then add them to the issues.
              //add epic keys to issues, first get the keys and then add them to the issues.

              if (
                // eslint-disable-next-line no-restricted-globals
                confirm('Please refresh the page and check if all the Epics are correctly processed, otherwise rePaste')
              ) {
                window.location.reload()
              } else {
                this.setState(prevState => {
                  return { appIsGettingData: prevState.appIsGettingData - 1 }
                })
              }
            })
        } else {
          //if existing epics are pasted
          this.setState(prevState => {
            return { appIsGettingData: prevState.appIsGettingData + 1 }
          })
          this.addEpicsToIssues([], newEpics, changes)
          this.props.setIssues(this.props.project.projectSelected.projectId).then(() => {
            this.setState(prevState => {
              return { appIsGettingData: prevState.appIsGettingData - 1 }
            })
          })
        }
        return false
      }
      if (changes[0][1] === 2) {
        //version paste

        //version bulk create hier
        return true
      }
      if (changes[0][1] === 3) {
        //component bulk create hier
        return true
      }
      return true
      //prevent afterChange hook being triggered by paste
    }
  }

  afterChange = changes => {
    let localEmptyRows = JSON.parse(localStorage.getItem('empty_rows'))
    if (changes === null) {
    } else {
      changes.map(change => {
        let rowIndex = change[0]
        let columnIndex = change[1]
        let oldValue = change[2]
        let newValue = change[3]
        let issueId
        let rowData = this.hotTableComponent.current.hotInstance.getDataAtRow(rowIndex)
        if ((rowData[0] === null || rowData[0] === '') && columnIndex !== 1) {
          return null
        }

        if (rowData[4]) {
          //alleen issueid pakken als de issue al is aangemaakt
          let domElement = document.createRange().createContextualFragment(rowData[4])
          issueId = domElement.children[0].textContent
        }
        if ((newValue === '' && oldValue === null) || (newValue === '' && oldValue === '')) {
          return null
        } else if (
          newValue !== '' &&
          oldValue !== newValue &&
          (oldValue === null || oldValue === undefined || oldValue === '')
        ) {
          if (columnIndex === 0) {
            //nieuwe issue
            if (localEmptyRows) {
              if (localEmptyRows.includes(rowIndex)) {
                let indexOfEmpty = localEmptyRows.indexOf(rowIndex)
                delete localEmptyRows[indexOfEmpty]
                let newLocalEmptyRows = Object.values(
                  localEmptyRows.filter(row => {
                    return row !== null
                  }),
                )
                localStorage.setItem('empty_rows', JSON.stringify(newLocalEmptyRows))
              }
            }

            let conditionStack = this.hotTableComponent.current.hotInstance.getPlugin('Filters')
            let versionValue
            let componentValue
            let epicKey

            let filters = conditionStack.conditionCollection.conditions.conjunction
            if (filters[2]) {
              let epicFilters = filters[2]
              if (epicFilters !== undefined && epicFilters.length !== 0) {
                if (epicFilters[0].args[0].length === 1) {
                  let epicValue = epicFilters[0].args[0][0]
                  epicKey = this.checkExistingEpics(epicValue)
                }
              }
            }
            if (filters[3]) {
              let versionFilters = filters[3]
              if (versionFilters !== undefined && versionFilters.length !== 0) {
                if (versionFilters[0].args[0].length === 1) {
                  versionValue = versionFilters[0].args[0][0]
                }
              }
            }
            if (filters[4]) {
              let componentFilters = filters[4]
              if (componentFilters !== undefined && componentFilters.length !== 0) {
                if (componentFilters[0].args[0].length === 1) {
                  componentValue = componentFilters[0].args[0][0]
                }
              }
            }

            postNewIssue(
              this.props.project.projectSelected.projectId,
              newValue,
              7,
              epicKey,
              versionValue,
              componentValue,
            ).then(response => {
              let issueKey = `<div class=""><a class="issue-key" href="https://hoproj.atlassian.net/browse/${response.data.key}" target="_blank">${response.data.key}</a></div>`
              this.hotTableComponent.current.hotInstance.setDataAtCell(rowIndex, 4, issueKey)
            })
          } else if (columnIndex === 1) {
            //nieuwe epic
            this.newEpic(issueId, newValue, rowData)
          } else if (columnIndex === 2) {
            //nieuwe versie
            this.newVersion(issueId, newValue, rowData, rowIndex)
          } else if (columnIndex === 3) {
            //new component
            this.newComponent(issueId, newValue, rowData)
          } else if (columnIndex === 5) {
            //new description
            this.generalUpdateIssue(issueId, rowData, oldValue, 'description')
          }
        } else if (newValue !== '' && oldValue !== newValue && oldValue !== null) {
          if (columnIndex === 0) {
            //update issue
            this.generalUpdateIssue(issueId, rowData, oldValue, 'issue')
          } else if (columnIndex === 1) {
            //update epic
            this.newEpic(issueId, newValue, rowData)
          } else if (columnIndex === 2) {
            //updateVersion
            this.newVersion(issueId, newValue, rowData, rowIndex)
          } else if (columnIndex === 3) {
            //update component
            this.newComponent(issueId, newValue, rowData)
          } else if (columnIndex === 5) {
            //update description
            this.generalUpdateIssue(issueId, rowData, oldValue, 'description')
          }
        } else if (newValue === '') {
          if (columnIndex === 0) {
            //empty issue
            this.emptyIssue(issueId, oldValue, rowData)
          } else if (columnIndex === 1) {
            //empty epic
            this.emptyEpic(issueId, oldValue, rowData)
          } else if (columnIndex === 2) {
            //empty version
            this.emptyVersion(issueId, oldValue, rowData)
          } else if (columnIndex === 3) {
            //empty components
            this.emptyComponent(issueId, oldValue, rowData)
          } else if (columnIndex === 5) {
            //empty description
            this.generalUpdateIssue(issueId, rowData, oldValue, 'description')
          }
        }
        return null
      })
    }
  }

  beforePaste = (data, coords) => {
    let singleColPasted = true
    data.map(data => {
      if (data.length !== 1) {
        singleColPasted = false
      }
      return true
    })
    return singleColPasted
  }

  generalUpdateIssue = (issueId, rowData, oldValue, caller) => {
    updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], oldValue, caller).catch(() => {
      alert('Oeps er is iets fout gegaan. Ververs de pagina')
    })

    let columnData
    let newValue

    switch (caller) {
      case 'issue':
        return
      case 'epic':
        newValue = rowData[1]
        columnData = this.hotTableComponent.current.hotInstance.getDataAtCol(1)
        break
      case 'version':
        newValue = rowData[2]
        columnData = this.hotTableComponent.current.hotInstance.getDataAtCol(2)
        break
      case 'component':
        newValue = rowData[3]
        columnData = this.hotTableComponent.current.hotInstance.getDataAtCol(3)
        break
      default:
        break
    }
    if (newValue === '') {
      this.checkExistingFieldsForMatch(oldValue, columnData, caller)
    }
  }

  checkExistingFieldsForMatch = (oldValue, columnData, caller) => {
    let existingMatch = columnData.filter(field => {
      return field === oldValue
    })

    if (existingMatch.length === 0) {
      let id
      switch (caller) {
        case 'epic':
          id = this.props.issue.issueList.issues.filter(field => {
            return field.fields.customfield_10009 === oldValue && field.fields.issuetype.id === '6'
          })
          break
        case 'version':
          id = this.props.versions.versionList.filter(field => {
            return field.name === oldValue
          })
          break
        case 'component':
          id = this.props.components.componentList.filter(field => {
            return field.name === oldValue
          })
          break
        default:
          break
      }

      deleteField(id[0].id, caller)
    }
  }

  emptyIssue = (issueId, oldValue, rowData) => {
    this.generalUpdateIssue(issueId, rowData, oldValue, 'issue')
  }
  emptyEpic = (issueId, oldValue, rowData) => {
    this.generalUpdateIssue(issueId, rowData, oldValue, 'epic')
  }

  emptyVersion = (issueId, oldValue, rowData) => {
    this.generalUpdateIssue(issueId, rowData, oldValue, 'version')
  }

  emptyComponent = (issueId, oldValue, rowData) => {
    this.generalUpdateIssue(issueId, rowData, oldValue, 'component')
  }

  getAllEpics = () => {
    let epics = this.props.issue.issueList.issues.filter(issue => {
      return issue.fields.issuetype.id === '6'
    })
    return epics.map(epic => {
      return epic.fields.customfield_10009
    })
  }

  checkExistingEpics = epic => {
    const matchedEpics = this.props.issue.issueList.issues.filter(issue => {
      return issue.fields.issuetype.id === '6' && issue.fields.customfield_10009 === epic
    })
    const localMatchedEpics = this.state.localEpics.filter(localEpics => {
      const objectValuesEpics = Object.values(localEpics).map(localEpic => {
        return localEpic
      })
      return epic === objectValuesEpics[0]
    })

    if (localMatchedEpics.length) {
      return Object.keys(localMatchedEpics[0])[0]
    } else if (matchedEpics.length) {
      return matchedEpics[0].key
    } else {
      return matchedEpics
    }
  }

  checkExistingVersions = versionValue => {
    return this.props.versions.versionList.filter(version => {
      return version.name === versionValue
    })
  }
  checkExistingComponents = componentValue => {
    return this.props.components.componentList.filter(component => {
      return component.name === componentValue
    })
  }

  newEpic = (issueId, newValue, rowData) => {
    let matchedEpic = this.checkExistingEpics(newValue)
    if (matchedEpic.length > 0) {
      updateIssue(issueId, rowData[0], matchedEpic, rowData[2], rowData[3], rowData[5], '', 'epic').catch(error => {
        console.log(error, 'deze ging niet goed?')
      })
    } else {
      postNewEpic(this.props.project.projectSelected.projectId, newValue, 6).then(response => {
        let epicId = response.data.key
        this.setState(prevState => ({ localEpics: [...prevState.localEpics, { [epicId]: newValue }] }))
        updateIssue(issueId, rowData[0], epicId, rowData[2], rowData[3], rowData[5], '', 'epic').catch(error => {
          console.log(error)
        })
      })
    }
  }

  newVersion = (issueId, newValue, rowData, rowIndex) => {
    let matchedVersion = this.checkExistingVersions(newValue)

    if (matchedVersion.length > 0) {
      //als de version al bestond

      updateIssue(issueId, rowData[0], rowData[1], matchedVersion[0].name, rowData[3], rowData[5], '', 'version').catch(
        error => {
          alert('Oeps er is iets fout gegaan. Ververs de pagina')
        },
      )
    } else {
      //Als het een gloednieuwe version is
      postNewVersion(this.props.project.projectSelected.projectId, newValue)
        .then(response => {
          updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'version').catch(() => {
            console.log(issueId, rowData)
            updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'version')
          })
        })
        .catch(() => {
          updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'version').catch(() => {
            console.log(issueId, rowData)
            updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'version')
          })
        })
    }
  }

  newComponent = (issueId, newValue, rowData) => {
    let matchedComponent = this.checkExistingComponents(newValue)

    if (matchedComponent.length > 0) {
      //als de component al bestond
      updateIssue(issueId, rowData[0], rowData[1], rowData[2], matchedComponent[0].name, rowData[5], '', 'component')
    } else {
      //Als het een gloednieuwe component is

      postNewComponent(this.props.project.projectList[this.props.project.projectSelected.projectId].key, newValue)
        .then(() => {
          updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'component')
        })
        .catch(error => {
          console.log(error)
          updateIssue(issueId, rowData[0], rowData[1], rowData[2], rowData[3], rowData[5], '', 'component')
        })
    }
  }

  addEpicsToIssues = (uniqueNewEpicKeys, newEpics, allPastedEpicsWithPosition) => {
    const issueKeysToUpdate = allPastedEpicsWithPosition.map(epic => {
      let rowData = this.hotTableComponent.current.hotInstance.getDataAtRow(epic[0])
      let domElement = document.createRange().createContextualFragment(rowData[4])
      return domElement.children[0].textContent
    })

    const newEpicsWithKeys = new Map()
    newEpics.forEach((newEpic, i) => {
      newEpicsWithKeys.set(newEpic, uniqueNewEpicKeys[i].key)
    })

    const issueKeysWithEpics = new Map()
    issueKeysToUpdate.forEach((issueKey, i) => {
      issueKeysWithEpics.set(issueKey, allPastedEpicsWithPosition[i][3])
    })

    const epicKeys = issueKeysToUpdate.map(issueKey => {
      let epicName = issueKeysWithEpics.get(issueKey)
      let epicKey = newEpicsWithKeys.get(epicName)
      if (!epicKey) {
        epicKey = this.checkExistingEpics(epicName)
      }
      return epicKey
    })

    issueKeysToUpdate.map((issueKey, i) => {
      return addEpicToIssueJira(issueKey, epicKeys[i])
    })
  }
  removeDuplicateEpics = (changes, allAvailableEpics) => {
    const changedEpics = new Set()
    changes.forEach(change => {
      changedEpics.add(change[3])
    })
    return [...changedEpics].filter(epic => !allAvailableEpics.includes(epic))
  }

  render() {
    let loaderText = ''
    if (this.props.appIsGettingData || this.state.appIsGettingData > 0) {
      loaderText = <p>The app is currently getting data</p>
    }
    return (
      <div>
        <ContentWrapper>
          {this.props.project.projectList.length && this.props.project.projectSelected.projectId ? (
            <PageHeaderClass
              title={this.props.project.projectList[this.props.project.projectSelected.projectId].name}
              projectId={this.props.project.projectSelected.projectId}
              page='storypage'
              projectKey={this.props.project.projectList[this.props.project.projectSelected.projectId].key}
            />
          ) : (
            ''
          )}

          <BallBeat color={'#123abc'} loading={this.props.appIsGettingData || this.state.appIsGettingData} />
          {loaderText}
          <div className='hot-container'>
            <HotTable
              ref={this.hotTableComponent}
              data={this.getIssues(Object.values(this.props.issue.issueList.issues))}
              licenseKey={'non-commercial-and-evaluation'}
              rowHeaders={true}
              colHeaders={['User Story', 'Epic', 'Version', 'Component', 'Issue Key', 'Description', 'Tier']}
              renderAllRows={true}
              columns={[
                {
                  editor: 'text',
                  className: 'userStory',
                },
                {
                  type: 'text',
                },
                {
                  type: 'text',
                },
                {
                  type: 'text',
                },
                {
                  renderer: 'html',
                  editor: false,
                },
                {
                  renderer: 'html',
                  editor: 'text',
                  className: 'description',
                },
                {
                  editor: 'text',
                },
              ]}
              rowHeights={23}
              stretchH={'all'}
              allowInvalid={false}
              contextMenu={true}
              minSpareRows={200}
              minSpareCols={0}
              dropdownMenu={[
                'make_read_only',
                'filter_by_condition',
                'filter_operators',
                'filter_by_condition2',
                'filter_by_value',
                'filter_action_bar',
              ]}
              //filters={[0,2,3,4,5, 6, 8, 9, 10]}
              filters={true}
              colWidths={index => {
                return this.getCalculatedColWidth(index)
              }}
              afterGetColHeader={(col, TH) => {
                this.addInput(col, TH)
              }}
              //  manualColumnResize={[30, "450", "", "", "", "", 30, 125, 30, "", ""]} //dit zorgt voor de eerste width, rest van de cols is auto
              manualColumnResize={true}
              preventOverflow={'horizontal'}
              manualRowResize={true}
              afterOnCellMouseOver={(event, coords, TD) => {
                this.onCellHover(event, coords, TD)
              }}
              afterOnCellMouseOut={(event, coords, TD) => {
                this.onCellHoverOut(event, coords, TD)
              }}
              beforeOnCellMouseDown={(event, coords) => {
                this.doNotSelectColumn(event, coords)
              }}
              afterOnCellMouseDown={(mouseEvent, coords, td) => this.afterMouseDown(mouseEvent, coords, td)}
              beforeChange={(data, source) => {
                return this.beforeChange(data, source)
              }}
              afterChange={changes => {
                this.afterChange(changes)
              }}
              beforePaste={(data, coords) => {
                return this.beforePaste(data, coords)
              }}
            />
          </div>
        </ContentWrapper>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authToken: state.auth.userToken,
    auth: state.auth,
    components: state.component,
    project: state.projects,
    issue: state.issue,
    versions: state.versions,
    issueTypes: state.issueTypes,
    transition: state.transition,
    assignees: state.assignee,
    priorities: state.priority,
    filters: state.filter,
    emptyRows: state.emptyRows,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setIssues,
    deleteIssue,
    setVersions,
    setComponents,
    setIssueTypes,
    setAssignees,
    setTransition,
    setPriorities,
    setActiveFilters,
    setEmptyRows,
  })(IssueHotTable),
)
