import React from 'react'
import ReactDOM from 'react-dom'
import MainRouter from './modules/MainRouter'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './reducers'
import reduxThunk from 'redux-thunk'
import './css/base.css'

const persistedState = loadFromLocalStorage()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(reduxThunk)))

store.subscribe(() => saveToLocalStorage(store.getState()))

const saveToLocalStorage = state => {
  const serializedState = JSON.stringify(state)

  try {
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.log(e.message)
  }
}

function loadFromLocalStorage() {
  const serializedState = localStorage.getItem('state')
  if (serializedState === null) return undefined
  return JSON.parse(serializedState)
}

// eslint-disable-next-line no-undef
ReactDOM.render(
  <Provider store={store}>
    <MainRouter />
  </Provider>,
  document.getElementById('app-root'),
)
