import { SET_VERSIONS, SET_VERSION_SELECTED } from '../actions/types'

const INITIAL_STATE = {
  versionList: null,
  versionSelected: null,
}

const versionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VERSIONS:
      return { ...state, versionList: action.payload }
    case SET_VERSION_SELECTED:
      return { ...state, versionSelected: action.payload }
    default:
      return state
  }
}
export default versionReducer
