import { SET_COMPONENT_SELECTED, SET_COMPONENTS } from '../actions/types'

const INITIAL_STATE = {
  componentList: null,
  componentSelected: null,
}

const componentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPONENTS:
      return { ...state, componentList: action.payload }
    case SET_COMPONENT_SELECTED:
      return { ...state, componentSelected: action.payload }
    default:
      return state
  }
}
export default componentReducer
