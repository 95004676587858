import { SET_BUDGET } from '../actions/types'

const INITIAL_STATE = {
  budget: [],
}

const budgetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BUDGET:
      return { ...state, budget: action.payload }
    default:
      return state
  }
}
export default budgetReducer
