import { SET_RISK_RANGE_LOW, SET_RISK_RANGE_HIGH } from '../actions/types'

const INITIAL_STATE = {
  riskRangeLow: 0,
  riskRangeHigh: 0,
}

const riskRangeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RISK_RANGE_LOW:
      return { ...state, riskRangeLow: action.payload }
    case SET_RISK_RANGE_HIGH:
      return { ...state, riskRangeHigh: action.payload }
    default:
      return state
  }
}
export default riskRangeReducer
