import { SET_HOURLY_RATE } from '../actions/types'

const INITIAL_STATE = {
  hourlyRate: [],
}

const hourlyRateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_HOURLY_RATE:
      return { ...state, hourlyRate: action.payload }
    default:
      return state
  }
}
export default hourlyRateReducer
