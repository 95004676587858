import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import GlobalNavigation from '@atlaskit/global-navigation'
import { Link } from 'react-router-dom'
import { setRecentProjects, setProjectSelected, signOut } from '../actions'
import history from '../history'
import { AtlassianIcon, JiraWordmark } from '@atlaskit/logo'
import {
  ContainerHeader,
  GroupHeading,
  HeaderSection,
  Item,
  LayoutManager,
  MenuSection,
  NavigationProvider,
  Separator,
  Wordmark,
} from '@atlaskit/navigation-next'
import '../css/NavigationNext.css'
import { BallBeat } from 'react-pure-loaders'
import ContentWrapper from './ContentWrapper'

class NavigationNext extends React.Component {
  constructor(props) {
    super(props)

    this.state = { isCollapsed: false }

    let params = window.location.pathname.split('/')
    switch (params[params.length - 1]) {
      case 'draaitabel':
        this.state = { currentPage: 'draaitabel' }
        break
      case 'simulatie':
        this.state = { currentPage: 'simulatie' }
        break
      case 'result':
        this.state = { currentPage: 'simulatie' }
        break
      default:
        this.state = { currentPage: 'issue' }
        break
    }
  }

  componentDidMount() {
    const item = document.getElementsByClassName('ak-navigation-resize-button')
    if (item) {
      try {
        item[0].addEventListener('click', function() {
          window.dispatchEvent(new Event('resize'))
        })
      } catch {}
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
    let params = window.location.pathname.split('/')

    if (
      (params[params.length - 1] !== 'draaitabel' || params[params.length - 1] !== 'simulatie') &&
      prevState.currentPage !== 'issue'
    ) {
      this.setState({ currentPage: 'issue' })
    }
  }

  handleClick(project) {
    this.props.setProjectSelected(project)
    this.setState({ currentPage: 'issue' })
    history.push(`/projects/${project}`)
  }

  _handleKeyDown = event => {
    // const { navigationUIController } = this.props;
    // if(event.keyCode === 219){
    //   this.setState({isCollapsed : false})
    //   this.navigationUIController.toggleCollapse()
    // }
    // if(event.keyCode === 221) {
    //   this.navigationUIController.toggleCollapse()
    //   this.setState({isCollapsed : true})
    // }
  }

  handleRefresh = () => {
    localStorage.removeItem('state')
    window.location.href = '/'
  }

  handleSignOut = () => {
    this.props.signOut()
    localStorage.removeItem('state')
    localStorage.removeItem('AuthToken')
    localStorage.removeItem('RefreshToken')
    window.location.href = '/'
  }

  MyGlobalNavigation = () => (
    <GlobalNavigation productIcon={() => <AtlassianIcon size='medium' />} onProductClick={() => {}} />
  )

  MyProductNavigation = () => (
    <Fragment>
      <HeaderSection>
        {({ className }) => (
          <div className={className}>
            <Wordmark wordmark={JiraWordmark} />
          </div>
        )}
      </HeaderSection>
      <MenuSection>
        {({ className }) => (
          <div className={className}>
            <Link to={'/'}>
              <Item
                onClick={() => {
                  this.setState({ currentPage: 'issue' })
                }}
                text='Projecten'
              />
            </Link>
            <Separator />
            <GroupHeading>Recente Projecten</GroupHeading>
            {this.props.project.recentProjectList.map((project, i) => {
              if (i < 11) {
                return <Item key={project.id} text={project.name} onClick={() => this.handleClick(project.id)} />
              }
              return null
            })}
            <Separator />
            <GroupHeading>Refresh Applicatie</GroupHeading>
            <Item
              onClick={() => this.handleRefresh()}
              before={() => {
                return <i className='refresh icon' />
              }}
              text='Refresh'
            />

            <Separator />
            <GroupHeading>Facturatie</GroupHeading>
            <Item text='Sprints'></Item>
            <Item text='Moneybird'></Item>
            <Item text='Gedraaide uren'></Item>
            <Separator />
            <Item text='Logout' onClick={() => this.handleSignOut()}></Item>
          </div>
        )}
      </MenuSection>
    </Fragment>
  )

  MyContainerNavigation = () => (
    <Fragment>
      <HeaderSection>
        {({ css }) => (
          <div className='project-title' css={{ ...css, paddingBottom: 20 }}>
            {this.props.project.projectList.length && this.props.project.projectSelected.projectId ? (
              <ContainerHeader
                text={this.props.project.projectList[this.props.project.projectSelected.projectId].name}
              />
            ) : (
              ''
            )}
          </div>
        )}
      </HeaderSection>
      <MenuSection>
        {({ className }) => (
          <div className={className}>
            <Link to={'/'}>
              <Item text='Projecten'></Item>
            </Link>
            <Separator />
            <GroupHeading>Project overzicht</GroupHeading>
            <Link to={`/projects/${this.props.project.projectSelected.projectId}`}>
              <Item
                onClick={() => {
                  this.setState({ currentPage: 'issue' })
                }}
                isSelected={this.state.currentPage === 'issue' ? true : false}
                text='Issue Overzicht'
              />
            </Link>
            <Link to={`/projects/${this.props.project.projectSelected.projectId}/draaitabel`}>
              <Item
                onClick={() => {
                  this.setState({ currentPage: 'draaitabel' })
                }}
                isSelected={this.state.currentPage === 'draaitabel' ? true : false}
                text='Draaitabel'
              />
            </Link>
            <Link to={`/projects/${this.props.project.projectSelected.projectId}/simulatie`}>
              <Item
                onClick={() => {
                  this.setState({ currentPage: 'simulatie' })
                }}
                isSelected={this.state.currentPage === 'simulatie' ? true : false}
                text='Simulatie inschatting'
              />
            </Link>
          </div>
        )}
      </MenuSection>
    </Fragment>
  )

  render() {
    if (this.props.project.recentProjectList && this.props.project.recentProjectList.length > 0) {
      return (
        <div className='full-navigation-component'>
          <NavigationProvider initialUIController={{ isCollapsed: this.state.isCollapsed }}>
            <LayoutManager
              globalNavigation={this.MyGlobalNavigation}
              productNavigation={this.MyProductNavigation}
              containerNavigation={window.location.pathname !== '/' ? this.MyContainerNavigation : null}
            />
          </NavigationProvider>
        </div>
      )
    } else {
      if (this.props.auth.isSignedIn) {
        this.props.setRecentProjects()
      }
      return (
        <ContentWrapper>
          <BallBeat color={'#123abc'} loading />
        </ContentWrapper>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    project: state.projects,
    auth: state.auth,
  }
}

export default connect(mapStateToProps, { setRecentProjects, setProjectSelected, signOut })(NavigationNext)
