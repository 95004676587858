import { SET_EMPTY_ROWS } from '../actions/types'

const INITIAL_STATE = {
  emptyRows: [],
}

const emptyRowsReducer = (state = INITIAL_STATE, action) => {
  //console.log(action)
  switch (action.type) {
    case SET_EMPTY_ROWS:
      return { ...state, emptyRows: action.payload }
    default:
      return state
  }
}
export default emptyRowsReducer
