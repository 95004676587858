import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {setVersionSelected, setVersions} from "../actions"
import "../css/Selector.css"

class VersionSelector extends React.Component {
  constructor(props){
    super(props)
    this.state = {options: []}
  }

  componentDidMount(){
   
    this.setState({options : this.getVersionsFormatted()})
     
  }

  getVersionsFormatted = () => {
    const versionList =  this.props.versions.versionList.map((version)=>{
      return {key : version.id, text: version.name, value: version.id}
    })
    versionList.unshift({key : "", text: "", value: ""})
    return versionList
  }

  render(){ 
    const versionId = this.props.versions.versionSelected ? this.props.versions.versionSelected.versionId : ""
    return (
      <div className="dropdown-version-wrapper">
        <Dropdown
          className="dropdown-version"
          placeholder='Versies' 
          defaultValue={versionId}
          fluid selection
          options={this.state.options} 
          onChange={(e, data)=> {this.props.setVersionSelected(data.value)}} 
          error={!this.props.error}
        />
      </div>
   
    )
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects,
    issue: state.issue,
    versions : state.versions
  };
};

export default connect(mapStateToProps, {setVersionSelected, setVersions})(VersionSelector);
