export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_PROJECT_SELECTED = 'SET_PROJECT_SELECTED'
export const SET_ISSUES = 'SET_ISSUES'
export const SET_RECENT_PROJECTS = 'SET_RECENT_PROJECTS'
export const SET_VERSIONS = 'SET_VERSIONS'
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES'
export const SET_TRANSITION = 'SET_TRANSITION'
export const SET_ASSIGNEES = 'SET_ASSIGNEES'
export const SET_PRIORITIES = 'SET_PRIORITIES'
export const ACTIVE_FILTERS = 'ACTIVE_FILTERS'
export const SET_EMPTY_ROWS = 'SET_EMPTY_ROWS'
export const SET_RISK_RANGE_LOW = 'SET_RISK_RANGE_LOW'
export const SET_RISK_RANGE_HIGH = 'SET_RISK_RANGE_HIGH'
export const SET_BUDGET = 'SET_BUDGET'
export const SET_HOURLY_RATE = 'SET_HOURLY_RATE'
export const SET_COMPONENTS = 'SET_COMPONENTS'
export const SET_COMPONENT_SELECTED = 'SET_COMPONENT_SELECTED'
export const SET_VERSION_SELECTED = 'SET_VERSION_SELECTED'
export const SET_LANGUAGE_SELECTED = 'SET_LANGUAGE_SELECTED'
export const SET_DISCOUNT = 'SET_DISCOUNT'
export const SET_DISCOUNT_TEXT = 'SET_DISCOUNT_TEXT'
export const SET_DISCOUNT_TITLE = 'SET_DISCOUNT_TITLE'
